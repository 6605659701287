.profileSettings {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  height: fit-content;
  min-height: 100%;
}

.profileSettingsHeadingContainer {
  display: flex;
  gap: 5rem;
}

.backBtn {
  padding: 8px 1rem;
  background-color: #007bff;
  border-radius: 8px;
  color: white;
}

.activitySettings {
  padding: 1rem;
  margin: 1rem;
  background-color: white;
  border-radius: 1rem;
  height: 32rem;
}

.resetBtn {
  background-color: #17a2b8;
  color: white;
  padding: 4px 8px;
  border-radius: 8px;
}
.heading {
  font-weight: 800;
  font-size: 1.2rem;
}
.playbackRangeSliderContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.playbackRangeValue {
  font-size: 1rem;
  font-weight: 600;
}
.playbackSpeedContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.playbackSpeedBtnContainer {
  display: flex;
  gap: 8px;
  margin-left: auto;
}
.selectVoiceContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1rem;
}
.hearGenie {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  gap: 8px;
  background-color: #ffc107;
  border-radius: 4px;
  margin-left: auto;
  width: fit-content;
  font-size: 14px;
  font-weight: 600;
}
.resetPlaybackBtn {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  gap: 8px;
  border-radius: 4px;
  margin-left: auto;
  width: fit-content;
  font-size: 14px;
  font-weight: 600;
  background-color: #17a2b8;
  color: white;
}

.selectSpeedContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1rem;
}

.hearGenieBtn {
  background-color: #ffc107;
  padding: 2px 1rem;
  border-radius: 8px;
  font-weight: 600;
  margin: 1rem;
}
.hearGenieBtn:hover {
  background-color: #ecbc2b;
}

.genieBtn {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  gap: 8px;
  background-color: #ffc107;
  border-radius: 4px;
}
.dotCardTypesContainer {
  margin: 1rem;
}
.dotCardTypeTitle {
  color: black;
}

.dotCardGroup {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.dotCardInputContainer {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.myProfileSettingsContainer {
  padding: 1rem;
  margin: 1rem;
  background-color: white;
  border-radius: 1rem;
  height: fit-content;
}

.myProfileForm {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.formSingleInput {
  display: flex;
  width: 100%;
}

.childsInputContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.profileNavigationBtn {
  padding: 8px 1rem;
  margin: 0 1rem;
  font-weight: 800;
  border-radius: 8px;
  background-color: transparent;
}

.activeNavigationBtn {
  padding: 8px 1rem;
  margin: 0 1rem;
  font-weight: 800;
  border-radius: 8px;
  background-color: #007bff;
  color: white;
}

.logoutBtn {
  padding: 8px 1rem;
  border-radius: 4px;
  color: white;
  background-color: red;
}

.logoutBtnContainer {
  display: flex;
  justify-content: flex-end;
}

/* Media Queries */

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1023px) {
}

@media screen and (max-width: 768px) {
  .profileSettings {
    padding: 4px;
  }
}

@media screen and (max-width: 480px) {
}
