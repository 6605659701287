.mainContainer{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.trophyContainer{
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: row;
    /* justify-content: space-around; */
    gap:5%;
    overflow-x: scroll;
    /* overflow-y: hidden; */
}
.innerTrophyContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    padding: 3%;
}

.trophy{
    /* position:absolute; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 50%;
    
}

.trophy img{
    /* height: 30%; */
    width: 40%;
}
@media only screen and (max-height: 426px) and (orientation:landscape) {
    .trophyContainer{
        height: 100%;
    }
  }
