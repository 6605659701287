.tncModalBackground {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.tncModalContainer {
  position: relative;
  z-index: inherit;
  width: 30rem;
  height: 20rem;
  background-color: white;
  color: grey;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 1rem;
  border-radius: 2rem;
}

.close_btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.close_btn i {
  font-size: 1.6rem;
}

.achivement_medal {
  width: 10rem;
  height: 10rem;
}

.medal_name {
  text-align: center;
  margin-top: 12px;
}
