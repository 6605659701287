/** @format */

.single_post {
  width: 24.36rem;
  /* width: 24rem; */
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 2rem;
  background-color: #ffffff;
  margin: 0 0 1rem 0;
  position: relative;

  /* Box Shadow */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.post_images {
  display: flex;
  justify-content: center;
  align-items: center;
}

.post_header__profile_picture {
  width: 4rem;
  height: 100%;
}

.post_header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.post_header__content__username {
  font-size: 1.2rem;
  font-weight: 600;
  color: #242428;
}
.post_header_content_username_parent {
  font-size: 0.7rem;
  color: grey;
}

.post_header__content__dateTime {
  font-size: 12px;
  color: grey;
}

.clap-img {
  width: 1.6rem;
}

.activityInfo__totalActivities__container p {
  font-size: 12px;
}

.achivement_medal {
  width: 2rem;
}
.achievement_no_medal {
  font-size: 4px;
  align-self: center;
  color: red;
}
.activityInfo {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  padding: 4px;
}

.activityInfo__totalActivities__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}
.activityInfo__totalActivities__container p {
  line-height: 1rem;
}
.activityInfo__totalActivities {
  align-self: center;
}
.activityInfo__totalActivities_time_spent {
  align-self: center;
  text-align: center;
}

.vertical_line {
  min-height: 70%;
  max-height: 100vh;
  margin: 0 4px;
  border: 0.5px solid grey;
  align-self: center;
}

.activityInfo__activity_stats_container {
  display: flex;
  gap: 4px;
}

.activityInfo__achievement__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.activityInfo__achievement__container p {
  line-height: 1rem;
  font-size: 12px;
}

.activityInfo__achievement {
  font-size: 10px;
  cursor: pointer;
  text-align: center;
}

.activityInfo_brain {
  color: #f79633;
}
.activityInfo_music {
  color: #bf8ffd;
}
.activityInfo_infinity {
  color: #ff8ad1;
}

.post_images__single_image {
  width: 100%;
  border-radius: 6px;
}

.post_description_container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.post_description_container__title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #242428;
}

.interaction_buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 4px;
}

.interaction_buttons__clap {
  background-color: transparent;
  cursor: pointer;
}
.interaction_buttons__clap__liked {
  background-color: transparent;
  cursor: pointer;
  color: #fc5200;
}

.interaction_buttons__comment {
  background-color: transparent;
  cursor: pointer;
}

.interaction_buttons__share {
  background-color: transparent;
  cursor: pointer;
}

.comment_box_background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.comment_box_wrapper {
  z-index: inherit;
  width: 33rem;
  height: 33rem;
  background-color: white;
  color: grey;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 1rem;
  position: relative;
  border-radius: 1rem;
}
.comment_box {
  position: relative;
  width: 100%;
  height: 100%;
}
.comment_box_wrapper__close {
  cursor: pointer;
  position: absolute;
  font-size: 1.4rem;
  top: 5px;
  right: 5px;
}

.comment_box__all_comments {
  height: 80%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: scroll; /* add scrollbar to the div */
  height: 400px; /* set the height of the div */
  margin-bottom: 1rem;
}

.comment_box_single_comment {
  padding: 4px;
  border: 0.8px solid gray;
  border-radius: 4px;
  padding: 4px 8px;
}

.comment_box__add_comment__input {
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid grey;
  border-radius: 4px;
  margin-top: -2rem;
  width: 100%;
}

.comment_box__submit_btn {
  padding: 4px;
  font-size: 14px;
  background-color: #fc5200;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.comment_box-header {
  display: flex;
  gap: 1rem;
}

.comment_img {
  width: 40px;
}

.comment_box__add_comment {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.post_edit_button {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  font-size: 1.3rem;
}
.post_edit_button i {
  font-size: 1.5rem;
}

/* Media Queries */

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1024px) {
  .single_post {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 480px) {
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  z-index: 1000;
  color: #2196f3;
  background-color: white;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-header h3 {
  margin: 0;
}

.modal-header button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.modal-header-main {
  display: flex;
  gap: 2rem;
}

@media only screen and (max-width: 600px) {
  .modal-header-main {
    display: grid;
    grid-template-columns: auto auto;
    background-color: white;
    width: 200px;
    align-items: center;
  }
}

.modal-footer button {
  background: #ddd;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.swiper_slide_wrapper {
  position: relative;
}
.swiper_slide_wrapper p {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 4px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 15px;
  padding: 2px 12px;
}

.comment_box_img {
  width: 50px;
  height: 50px;
}
