.steps_container {
  display: flex;
  /* border: 1px solid red; */
  align-items: center;
  margin: 4px 0;
  position: absolute;
  top: 8px;
}

.step {
  border: 1px solid gray;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.completed_step {
  background-color: #0445af;
  color: white;
  border: none;
}

.current_step {
  border: 2px solid #0445af;
  color: #0445af;
  font-weight: 600;
}

.step_line {
  border: 1px solid red;
  width: 50px;
}

.step_line_completed {
  border: 2px solid #0445af;
}

.step_line_incomplete {
  border: 1px solid gray;
}
