.tncModalBackground {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  background-color: #1f2d3db4;
  display: flex;
  justify-content: center;
}

.tncModalContainer {
  z-index: inherit;
  width: 30rem;
  height: fit-content;
  background-color: white;
  color: grey;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 2rem;
  border-radius: 2rem;
  position: relative;
  gap: 8px;
  margin-top: 1rem;
  font-family: "Roboto", sans-serif;
}

.closeBtn {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 2rem;
  cursor: pointer;
}

.text_item {
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 16px;
}
.text_item:not(:last-child) {
  margin-bottom: 10px;
}
.heading {
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 8px;
}

.text_item {
  display: list-item;
}

.footer_text {
  font-size: 0.8rem;
}
