.exercise_navigation {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  z-index: 2;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  transition: all 1s;
}
.crr_exercise {
  font-size: 3rem;
}
.button {
  background-color: transparent;
  border-radius: 5px;
}
.button i {
  font-size: 3rem;
}
.outer_wrapper {
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: center;
  position: fixed;
  top: 10vw;
  right: 2rem;
  border-radius: 10px;
  border: 5px solid;
  transition: all 1s;
}
.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.exe-number-wrapper {
  display: flex;
  background-color: white;
  border-radius: 50%;
  border: 3.5px solid;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 6vw;
  height: 6vw;
  box-shadow: 0 2px 4px darkslategray;
}
.current-exe-number {
  font-size: 3vw;
  font-family: sensei;
  display: flex;
  align-items: center;
}
.ex-span {
  font-size: 1.6vw;
}
.exercise_text {
  font-size: 1.7rem;
  font-weight: bold;
  font-family: sensei;
  position: relative;
  transform: rotate(-32deg);
  right: 5vw;
}
.exercise_text span {
  top: -30px;
  position: absolute;
  transform-origin: bottom center;
  height: 100px;
  width: 20px;
  display: inline-block;
  left: 0;
  top: 0;
}
.one {
  width: 20px;
  transform: rotate(2deg);
}
.two {
  width: 20px;
  transform: rotate(10deg);
}
.three {
  width: 20px;
  transform: rotate(18deg);
}
.four {
  width: 20px;
  transform: rotate(26deg);
}
.five {
  width: 20px;
  transform: rotate(34deg);
}
.six {
  width: 20px;
  transform: rotate(42deg);
}
.seven {
  width: 20px;
  transform: rotate(50deg);
}
.eight {
  width: 20px;
  transform: rotate(58deg);
}
@media only screen and (max-width: 992px) {
  .button i {
    font-size: 1.5rem;
  }
  .exe-number-wrapper {
    border: 2.5px solid;
  }
}
