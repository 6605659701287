.page_wrapper {
  overflow-y: hidden;
  border-radius: 20px;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}
.right_page {
  box-shadow: -10px 0px 10px 1px rgba(0, 0, 0, 0.24);
}
.left_page {
  box-shadow: 10px 0px 10px 1px rgba(0, 0, 0, 0.24);
}
/* .page_wrapper i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.4);
  color: whitesmoke;
  cursor: pointer;
  font-size: 5rem;
} */
.page_tint {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.6);
}

.video {
  height: 100%;
  width: 100%;
  object-fit: fill;
}
.logo_container {
  position: absolute;
  bottom: 9px;
  right: 9px;
  opacity: 0.95;
  height: 7vw;
  width: 7vw;
}
.logo_img {
  height: 100%;
  width: 100%;
}
.img_wrapper {
  height: 100%;
  width: 100%;
}
.book_background_img {
  width: 100%;
  height: 100%;
}
.recommendation_img_wrapper {
  position: absolute;
  bottom: 10px;
  left: 0px;
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
  height: 50%;
}
.story_image {
  transition: all;
  max-width: 45%;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
/* .story_image:hover {
  scale: 1.1;
  cursor: pointer;
} */
.action_page {
  height: 100%;
  width: 100%;
  background: url("https://cdn.wallpapersafari.com/41/11/F9LZCK.jpg");
  background-size: cover;
}
.button_wrapper {
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.action_button {
  border-radius: 50%;
  border: 10px solid #8fc74e;
  height: 20vw;
  width: 20vw;
  font-size: 2rem;
  font-weight: bold;
  color: white;
  background-color: #abd158;
  font-family: sensei;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.interaction_button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  display: flex;
}
.interaction_button i {
  padding: 15px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.4);
  color: whitesmoke;
  cursor: pointer;
  font-size: 5rem;
}
.page_action {
  position: absolute;
  top: 5px;
  z-index: 2;
  padding: 15px 27px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.4);
  color: whitesmoke;
  cursor: pointer;
  font-size: 5rem;
}
@media screen and (max-width: 1200px) {
  .action_button {
    font-size: 1.5rem;
  }
  .interaction_button i {
    font-size: 2.5rem;
  }
  .page_action {
    font-size: 2rem;
    padding: 8px 13px;
  }
}
