@keyframes ldio-wsemtpe4w1o {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.ldio-wsemtpe4w1o div {
  position: absolute;
  width: 52px;
  height: 52px;
  border: 12px solid #93dbe9;
  border-top-color: transparent;
  border-radius: 50%;
  z-index: 10;
}
.ldio-wsemtpe4w1o div {
  animation: ldio-wsemtpe4w1o 1s linear infinite;
  top: 50%;
  left: 50%;
}
.loadingio-spinner-rolling-9vl12ajybri {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
  display: inline-block;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 10;
}
.ldio-wsemtpe4w1o {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-wsemtpe4w1o div {
  box-sizing: content-box;
}
