.mainContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.parentContainer {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-around;
  gap: 10px;
}
/* .equationContainer {
  width: 60%;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 30px;
  gap: 5%;
  box-shadow: 0px -1px 10px 1px rgba(0, 0, 0, 0.4);
} */

.equationHolder {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.equationCard {
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 200%;
}

.columnCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .parentFunction {
  width: 10rem;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
} */

.parentHomeContainer {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas: "parent-activites parent-activites parent-activites parent-activites parent-activites parent-activites parent-activites parent-activites tab-bar";
}

.parentActivitiesDiv {
  display: grid;
  grid-area: parent-activites;
}

.tabBarDiv {
  display: grid;
  grid-area: tab-bar;
}

.tabBar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 4rem;
  gap: 4rem;
  align-items: center;
  box-shadow: 0px -1px 10px 1px rgba(0, 0, 0, 0.4);
  /* background-color: #0092cc; */
}

.tabBarIos {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 0;
  gap: 0;
  align-items: center;
  box-shadow: 0px -1px 10px 1px rgba(0, 0, 0, 0.4);
}

.navigationBtn {
  width: 3rem;
  height: fit-content;
}

.dashboardSingleCard {
  width: 15rem;
}

@media screen and (min-width: 1023px) {
  .tabBarIos {
    padding-top: 2rem;
    gap: 3rem;
  }
}

/* Media Queries */

@media screen and (max-width: 1280px) {
  .dashboardSingleCard {
    width: 11rem;
  }

  .tabBar {
    padding-top: 4rem;
    gap: 6rem;
  }
}

@media screen and (max-width: 1023px) {
  .navigationBtn {
    width: 1.8rem;
  }

  .dashboardSingleCard {
    width: 9rem;
  }

  .tabBar {
    padding-top: 2rem;
    gap: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .navigationBtn {
    width: 1.6rem;
  }

  .dashboardSingleCard {
    width: 7rem;
  }

  .tabBar {
    padding-top: 1.6rem;
    gap: 2rem;
  }
}

@media screen and (max-width: 480px) {
  .navigationBtn {
    width: 1rem;
  }

  .dashboardSingleCard {
    width: 3rem;
  }
}
