.mainContainer{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.creationContainer{
    display: flex;
    flex-direction:column ;
    align-items: center;
    justify-content: center;
    height:60%;
    width: 70%;
    max-height: 60rem;
    gap: 2rem;
    padding: 10px;
    border-radius: 20px;
    box-shadow: 0px -1px 10px 1px rgba(0, 0, 0, 0.4);
}
.creationContainer h1{
    font-size: 200%;
}

.deviceContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 70%;
    width: 100%;
}


.deviceComponent{
    display: flex;
    justify-content: center;
    align-items: center;
    
    border-radius: 50%;
    transition: all .2s ease-in-out;
}


.deviceComponent:hover{
    transform: scale(1.1);
}

.deviceContainer input{
    margin:0;padding:0;
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
}

.deviceContainer input:active +.deviceLabel{opacity: .9;}
.deviceContainer input:checked +.deviceLabel{
    -webkit-filter: none;
       -moz-filter: none;
            filter: none;
            border: 5px solid rgb(31, 29, 194);
            
}
.deviceLabel{
    cursor:pointer;
    background-size:contain;
    background-repeat:no-repeat;
    display:inline-block;
    border-radius: 50%;
    -webkit-transition: all 100ms ease-in;
       -moz-transition: all 100ms ease-in;
            transition: all 100ms ease-in;
    
}

@media only screen and (max-height: 426px) and (orientation:landscape) {
    .deviceComponent{
            border-radius: 50%;
        
    }
        .creationContainer{
        height: 100%;
        max-height: 100%;
        width: 100%;
        box-shadow: 0px -1px 10px 1px rgba(0, 0, 0, 0);
    }
    
  }

