.modalContent {
  width: 100%;
  min-height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 3rem;
}

.braincells_logo {
  position: absolute;
  left: 8px;
  top: 8px;
  width: 50px;
}

.modalContent label {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.modalQuestions {
  font-weight: 500;
  font-size: 24px;
}

.modalInput {
  margin: 1rem auto;
  border-radius: 10px;
  display: block;
  width: 100%;
  font-family: inherit;
  padding: 0px 0px 8px;
  border: none;
  outline: none;
  border-radius: 0px;
  appearance: none;
  background-image: none;
  background-position: initial;
  background-size: initial;
  background-repeat: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  transform: translateZ(0px);
  font-size: 30px;
  -webkit-font-smoothing: antialiased;
  line-height: unset;
  animation: 1ms ease 0s 1 normal none running native-autofill-in;
  transition: background-color 1e8s ease 0s, box-shadow 0.1s ease-out 0s;
  box-shadow: rgba(4, 69, 175, 0.3) 0px 1px;
  color: rgb(4, 69, 175);
  background-color: transparent !important;
}
.modalInput::placeholder {
  color: rgba(4, 69, 175, 0.3);
}
.modalInput:-ms-input-placeholder {
  color: rgba(4, 69, 175, 0.3);
}

.modalInput::-ms-input-placeholder {
  color: rgba(4, 69, 175, 0.3);
}

.modalBtn {
  position: fixed;
  bottom: 5px;
  font-family: inherit;
  line-height: inherit;
  font-weight: 700;
  cursor: pointer;
  transition-duration: 0.1s;
  transition-property: background-color, color, border-color, opacity,
    box-shadow;
  transition-timing-function: ease-out;
  outline: none;
  border: 1px solid transparent;
  margin: 1rem 0 0 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px 0px;
  padding: 6px 14px;
  min-height: 40px;
  background-color: rgb(4, 69, 175);
  color: rgb(255, 255, 255);
  border-radius: 5px;
  left: 50%;
  transform: translateX(-50%);
}

.mcq_wrapper {
  padding: 1rem 2rem;
}
.radio_button_wrapper {
  display: flex;
  justify-content: flex-start;
  margin: 15px 0px;
  border: 1px solid black;
  padding: 4px 12px;
  border-radius: 1rem;
  color: black;
}
.radio_button_wrapper label {
  margin: 0px 10px;
}
.mcq_answers {
  margin-top: 20px;
  font-size: 1.2rem;
}
.mcq_modal_input {
  margin: 0px 0px 0px 10px;
  font-size: 20px;
}

.question_one {
  margin-top: 5rem;
}

.question_two {
  margin-top: 5rem;
}

.dropdown_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

@media only screen and (max-width: 600px) {
  .modalBtn {
    padding: 0.6rem 2.3rem;
    font-size: 0.8rem;
  }
  .modalInput {
    padding: 3px 4px;
    font-size: 1rem;
  }
}

/* Media Queries */

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 768px) {
  .braincells_logo {
    left: 8px;
    top: 4rem;
  }
}

@media screen and (max-width: 480px) {
  .modalContent {
  }
}
