.WeighMeIn {
  background-color: skyblue;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.optionsContainer {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 1.4rem;
  border-radius: 1rem;
  background-color: dodgerblue;
  margin-top: 8rem;
}

.optionImg {
  width: 7rem;
  height: 7rem;
  border-radius: 1rem;
}

.weighingContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 16rem;
}

.triangleImg {
  width: 5rem;
  height: 5rem;
  align-self: center;
}

.plankImg {
  width: 40rem;
}

.questionImg {
  width: 7rem;
  height: 7rem;
  border-radius: 1rem;
  position: absolute;
  top: -7rem;
  left: 4rem;
}

.answerImg {
  width: 7rem;
  height: 7rem;
  border-radius: 1rem;
  position: absolute;
  top: 0;
}

.answerDiv {
  width: 7rem;
  height: 7rem;
  border-radius: 1rem;
  position: absolute;
  top: -7rem;
  right: 4rem;
  background-color: white;
}

.optionDragTransition {
  transition: ease 1s;
}

.visibilityHidden {
  visibility: hidden;
}

.displayNone {
  display: none;
}

.upDownAnimate {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
