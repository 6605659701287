.program-page {
  display: flex;
  overflow-x: scroll;
}
.navigation-dot-wrapper {
  z-index: 2;
  position: absolute;
  width: 10%;
  background-color: rgba(255, 255, 255, 0.3);
  height: 5vh;
  top: 0px;
  left: 50%;
  transform: translate(-50%);
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.navigation-dot {
  z-index: 2;
  border-radius: 50%;
  background-color: rgba(139, 195, 74, 1);
  width: 20px;
  height: 20px;
  cursor: pointer;
}
@media only screen and (max-width: 992px) {
  .navigation-dot-wrapper {
    height: 8vh;
    width: 15%;
  }
}
