.mainContainer{
    
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.creationContainer{
    display: flex;
    flex-direction:column ;
    align-items: center;
    justify-content: space-around;
    height:60%;
    width: 70%;
    max-height: 60rem;
    gap: 2rem;
    padding: 10px;
    /* border:1px solid rgb(168, 168, 168); */
    border-radius: 20px;
    box-shadow: 0px -1px 10px 1px rgba(0, 0, 0, 0.4);
}
.creationContainer h1{
    font-size: 200%;
}

.creationContainer input{
    font-size: 2rem;
    width: 80%;
    height: 4rem;
    border-radius: 10px;
}

.dateTimePicker{
    width: 50%;
    height: 50%;
}

@media only screen and (max-height: 426px) and (orientation:landscape) {
    .creationContainer{
        height: 100%;
        max-height: 100%;
        width: 100%;
        box-shadow: 0px -1px 10px 1px rgba(0, 0, 0, 0);
    }
  }

