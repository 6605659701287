.dashCardBtn {
  width: 18rem;
  height: 18rem;
  margin: 0 2rem;
}

/* Media Queries */

@media screen and (max-width: 1280px) {
  .dashCardBtn {
    width: 20rem;
    height: 20rem;
    margin: 0;
  }
}

@media screen and (max-width: 1024px) {
  .dashCardBtn {
    width: 15rem;
    height: 15rem;
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  .dashCardBtn {
    width: 9rem;
    height: 9rem;
    margin: 0;
  }
}

@media screen and (max-width: 480px) {
  .dashCardBtn {
    width: 17rem;
    height: 17rem;
    margin: 0;
    /* border: 1px solid red; */
  }
}
