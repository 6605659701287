.faqContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  min-height: 100%;
  height: fit-content;
}

.heading {
  font-size: 1.2rem;
  font-weight: 600;
}

.accordianContainer {
  width: 75%;
}

.headingContainer {
  display: flex;
  align-items: center;
  gap: 5rem;
}

.backBtn {
  padding: 8px 1rem;
  background-color: #3dc2ff;
  border-radius: 8px;
  color: white;
}
