:root {
  /* --primary-color:#2079c2; */
  --primary-color: #5b5750;
}

.mainContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.creationContainer {
  width: 50rem;
  min-height: 20rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  /* border: 1px solid black; */
  background-color: rgba(255, 255, 255, 0.39);
  border-radius: 25px;
}

.reportContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.reportComponent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 12rem;
  height: 12rem;
  border-radius: 50%;
  background-color: #f2f7fd;
  border: 5px solid var(--primary-color);
}

.reportScore {
  font-size: 3rem;
  font-family: sensei;
  color: var(--primary-color);
}

.reportName {
  font-family: sensei;
  color: var(--primary-color);
  font-size: 2rem;
}

@media only screen and (max-width: 426px) {
  .mainContainer {
    grid-template-rows: 100%;
  }
  .creationContainer {
    overflow: scroll;
  }
}
@media only screen and (max-height: 426px) and (orientation: landscape) {
  .mainContainer {
    grid-template-rows: 100%;
  }
  .creationContainer {
    overflow: scroll;
  }
  .avatar {
    bottom: -12%;
  }
}
