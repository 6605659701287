.excercise-page {
  position: relative;
  background-color: #daebfc;
}

.bg-img {
  min-width: 100vw;
  margin: 0;
  margin-left: -3px;
  height: 100vh;
}

.excercise-main {
  position: absolute;
  top: 1%;
  left: 2%;
  right: 3.5%;
  bottom: 1%;
  display: flex;
  gap: 2vw;
}

.activity-list {
  /* height: 100%; */
  border-radius: 30px;
  padding: 15px 0px;
  min-width: 15%;
  display: flex;
  flex-direction: column;
  gap: 3%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
  align-items: center;
  flex-basis: 150px;
  flex-grow: 0;
  flex-shrink: 0;
  overflow-x: hidden;
  /* background-color: aliceblue; */
}

.fade-effect-up {
  position: fixed;
  width: 20%;
  z-index: 1;
  pointer-events: none;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    #daebfc 70%
  );
  bottom: 0%;
  height: 4em;
}
.fade-effect-down {
  position: fixed;
  width: 20%;
  z-index: 1;
  pointer-events: none;
  background-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 0),
    #daebfc 70%
  );
  top: 0%;
  height: 4em;
}
.scroll-bottom {
  width: 100%;
  position: absolute;
  bottom: 9px;
  font-size: 4rem;
  color: white;
  cursor: pointer;
  z-index: 10;
  background-color: #26c1fc;
}
.scroll-top {
  position: absolute;
  width: 100%;
  top: 0px;
  font-size: 4rem;
  color: white;
  cursor: pointer;
  z-index: 10;
  background-color: #26c1fc;
}
.scroll-top i {
  color: white;
}
.scroll-bottom i {
  color: white;
}
.activity-list:hover .scroll-top .scroll-bottom {
  display: block;
}
.activity-list::-webkit-scrollbar {
  display: none;
}

.activity-button {
  cursor: pointer;
  position: relative;
  /* color: #f4f5f8; */
  color: #f4f5f8;
  /* width: 83%; */
  width: 150px;
  transition: all 0.5s;
  /* flex-basis: 150px;
    flex-grow: 0;
    flex-shrink: 0; */
  /* transform: translate(-50%); */
  /* animation: pulse 5s infinite; */
}
.activity_card {
  background-color: #344966;
  border-radius: 100%;
  height: 150px;
  flex-basis: 150px;
  flex-grow: 0;
  flex-shrink: 0;
}
.inner-border {
  border: 7px white solid;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.activity-details {
  position: absolute;
  width: 85%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  row-gap: 1vh;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.activity-details-icon {
  font-size: 4.3vh;
}

.activity-details-text {
  font-size: 2.6vh;
  font-family: "Nunito Regular";
  font-weight: 500;
}

.activity {
  flex-grow: 1;
  padding: 1rem;
  display: flex;
  align-items: center;
  position: relative;
}

.activity-bg {
  width: 100%;
}

.activity-start-btn {
  cursor: pointer;
  position: absolute;
  width: 35%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: pulse 3s infinite;
}

.theme-name {
  text-align: center;
  font-family: sensei;
  position: absolute;
  top: 1%;
  left: 50%;
  animation: pulse 5s infinite;
  transform: translate(-50%);
  z-index: 2;
  font-size: 1.6rem;
  text-align: center;
  opacity: 0.75;
  transition: all 1s;
}
.activity-name {
  text-align: center;
  font-family: sensei;
  position: absolute;
  top: 11%;
  left: 50%;
  animation: pulse 5s infinite;
  transform: translate(-50%);
  z-index: 2;
  font-size: 3.5rem;
  text-align: center;
  opacity: 0.75;
  transition: all 1s;
}
@-webkit-keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }

  50% {
    transform: translate(-50%, -50%) scale(1.1);
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }

  50% {
    transform: translate(-50%, -50%) scale(1.1);
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

@media only screen and (max-width: 1250px) {
  .theme-name {
    top: 3%;
  }
  .activity-name {
    top: 13%;
    font-size: 2rem;
    text-align: center;
    left: 47%;
  }
  .activity-start-btn {
    width: 25%;
    left: 45%;
  }
  .activity-button {
    width: 120px;
  }
  .activity_card {
    height: 120px;
  }

  .activity-details-icon {
    font-size: 3vh;
  }

  .activity-details-text {
    font-size: 2.2vh;
  }
}

@media only screen and (max-width: 992px) {
  .theme-name {
    top: 3%;
    font-size: 1.3rem;
    left: 45%;
  }
  .activity-name {
    top: 15%;
    font-size: 2rem;
    text-align: center;
    left: 44.5%;
  }
  .activity-start-btn {
    width: 25%;
    left: 42%;
  }
  .activity-button {
    width: 100px;
  }
  .activity_card {
    height: 100px;
  }

  .activity-details-icon {
    font-size: 5vh;
  }

  .activity-details-text {
    font-size: 3.2vh;
  }
}
@media only screen and (max-width: 722px) {
  .theme-name {
    top: 3%;
    font-size: 1.3rem;
    left: 42%;
  }
  .activity-name {
    top: 18%;
    font-size: 2rem;
    text-align: center;
  }
  .activity-start-btn {
    width: 25%;
    left: 35%;
  }
  /* To control left panel activity cards size  */
  .activity-button {
    width: 100px;
  }
  .activity_card {
    height: 100px;
  }
  .activity-details-icon {
    font-size: 4.3vh;
  }

  .activity-details-text {
    font-size: 3vh;
  }
}
/* @media only screen and (max-width: 700px) {
    .activity-start-btn {
      width: 25%;
      left: 30%;
    }
  } */
