.feedback {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }
  
  .feedbackTitle {
    color: #7cb0df;
    font-size: 3rem;
    font-family: sensei;
    text-transform: capitalize;
  }
  
  .feedbackForm {
    width: 100%;
    height: 10rem;
    font-size: 1.5rem;
    border: 2px solid #7cb0df;
    color: #7cb0df;
    border-radius: 10px;
    resize: none;
  }
  .feedbackSubmit {
    color: white;
    padding: 5px 10px;
    margin-top: 1rem;
    text-transform: capitalize;
    font-size: 1.4rem;
    background-color: #7cb0df;
    border-radius: 1rem;
    font-weight: bold;
  }
  
  @media only screen and (max-width: 850px) {
    .feedbackTitle {
      font-size: 2.5rem;
    }
    .feedbackForm {
      font-size: 1rem;
      height: 8rem;
    }
  
    .feedbackSubmit {
      font-size: 1rem;
    }
  }
  
  @media only screen and (max-width: 700px) {
    .feedbackTitle {
      font-size: 2rem;
    }
    .feedbackForm {
      font-size: 1rem;
      height: 6rem;
    }
  }
  