.forward_button_wrapper {
  font-size: 3rem;
  opacity: 0.5;
  border-radius: 10px;
  overflow: hidden;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0px;
  top: 50%;
  background-color: rgba(255, 255, 255, 0.7);
  width: 30px;
  height: 50px;
  transform: translate(0%, -50%);
  z-index: 2;
}
.backward_button_wrapper {
  font-size: 3rem;
  opacity: 0.5;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0px;
  top: 50%;
  background-color: rgba(255, 255, 255, 0.7);
  transform: translate(0%, -50%);
  z-index: 2;
  width: 30px;
  height: 50px;
}
.nav_button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav_button i {
  font-size: 2rem;
}
