.nps {
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.npsContainer {
  /* height: 20rem; */
  padding: 10px;
  width: 60%;
  background-color: #ffffff;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  position: relative;
}

.npsClose {
  color: lightgray;
  height: 2rem;
  width: 2rem;
  font-size: 1.5rem;
  position: absolute;
  top: 7px;
  right: 0px;
  cursor: pointer;
}

.npsClose:hover {
  color: red;
}

@media only screen and (max-width: 700px) {
  .npsContainer {
    width: 80%;
    height: 15rem;
  }
}

@media only screen and (max-width: 500px) {
  .npsContainer {
    height: 10rem;
  }
}
