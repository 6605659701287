.referralSection {
  width: 65%;
  border-radius: 1rem;
  padding: 1rem;
  margin-top: 1rem;
  background-color: white;
}

.referralLinkContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 100%;
}

.linkContainer {
  width: 100%;
  border: 2px solid #007bff;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  color: #007bff;
}

.copyLinkButton {
  height: 100%;
  background-color: #007bff;
  color: white;
  padding: 0 2rem;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.copyLinkButtonSuccess {
  height: 100%;
  background-color: green;
  color: white;
  padding: 0 2rem;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.statusOfReferenceSection {
  margin-top: 2rem;
  background-color: white;
  width: 65%;
  border-radius: 1rem;
  padding: 1rem;
}

.birdImg {
  width: 20rem;
  text-align: center;
}

.couponCodeSection {
  margin-top: 2rem;
  background-color: white;
  width: 65%;
  border-radius: 1rem;
  padding: 1rem;
}

.redeemNowBtn {
  color: #007bff;
  background-color: transparent;
}

.couponTable {
  border: 1px solid #808080;
  width: 100%;
  margin-top: 1rem;
}

th {
  border: 1px solid #808080;
  padding: 8px;
  color: grey;
  font-weight: 600;
}

tr {
  border: 1px solid #808080;
}

td {
  border: 1px solid #808080;
  padding: 8px;
}

.couponTable td p {
  color: grey;
  font-weight: 500;
  font-size: 14px;
}

.referralTermsSection {
  margin-top: 2rem;
  background-color: white;
  width: 65%;
  border-radius: 1rem;
  padding: 1rem;
}

ul {
  list-style-type: disc;
  margin-top: 1rem;
}

li {
  display: list-item;
}

li p {
  font-size: 12px;
}

.headingContainer {
  display: flex;
  align-items: center;
  gap: 5rem;
}

.backBtn {
  padding: 8px 1rem;
  background-color: #007bff;
  border-radius: 8px;
  color: white;
}
