.deviceAccessContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.singleDeviceContainer {
  width: 16rem;
}

/* Media Queries */

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1024px) {
  .singleDeviceContainer {
    width: 12rem;
  }
}

@media screen and (max-width: 768px) {
  .singleDeviceContainer {
    width: 10rem;
  }
}

@media screen and (max-width: 480px) {
}
