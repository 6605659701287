.topic_cards_container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 20px;
  padding: 1rem;
  grid-row-gap: 2rem;
}

.program_title {
  color: #f37335;
  font-family: Sensei;
  font-weight: 500;
}

.back_button_container {
  width: 4rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

/* Media Queries */

@media screen and (max-width: 1280px) {
  .topic_cards_container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1024px) {
  .topic_cards_container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .topic_cards_container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 480px) {
  .topic_cards_container {
    grid-template-columns: repeat(2, 1fr);
  }

  .back_button_container {
    width: 3rem;
    right: 4px;
  }
}
