/** @format */

.bubble-container {
  position: absolute;
  width: 100vw;
  top: 0;
  height: 100vh;
  background-image: url("../../../../../../public/assets/parentLanding/background-cropped.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bubble-chart-container {
  width: 1200px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
}

@media all and (max-width: 1200px) {
  .bubble-chart-container {
    width: 890px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .bubble-container {
    top: 0.5rem;
  }
}

@media all and (max-width: 900px) {
  .bubble-chart-container {
    width: 890px;
    left: 50%;
    top: 15rem;
  }
}

@media all and (max-width: 700px) {
  .bubble-chart-container {
    width: 790px;
    left: 50%;
    top: 15rem;
  }
}
