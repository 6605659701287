.communityContainer {
  display: flex;
  flex-direction: column;
  padding: 2rem 8px;
  flex-wrap: wrap;
  gap: 4rem;
}

.socialButtons {
  width: 16rem;
}

.socialButtonContainer {
  display: flex;
  justify-content: space-around;
}

.blogsBtnContainer {
  width: 16rem;
  align-self: center;
}

/* Media Queries  */

@media screen and (max-width: 1280px) {
  .socialButtons {
    width: 13rem;
  }
  .blogsBtnContainer {
    width: 13rem;
  }
}

@media screen and (max-width: 1023px) {
  .socialButtons {
    width: 9rem;
  }
  .blogsBtnContainer {
    width: 9rem;
  }
  .communityContainer {
    padding: 8px;
    gap: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .socialButtons {
    width: 8rem;
  }

  .blogsBtnContainer {
    width: 8rem;
  }
}

@media screen and (max-width: 480px) {
}
