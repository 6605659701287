.container {
  display: flex;
  border-radius: 15px;
  flex-wrap: wrap;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  text-align: center;
  width: 70rem;
  z-index: 1;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
}
.parentAccessButtonContainer {
  position: absolute;
  top: 1px;
  right: 1rem;
  z-index: 2;
}
.parentAccessButton {
  width: 6rem;
  height: 6rem;
}

.communityButtonContainer {
  position: absolute;
  top: 1rem;
  left: 2rem;
  z-index: 2;
  width: 5rem;
  height: 5rem;
  /* border: 1px solid red; */
}

.communityButtonLink {
  width: 100%;
  height: 100%;
}

.parentCaptchaContainer {
  position: absolute;
  top: 6rem;
  right: 2rem;
}

.languageButtonContainer {
  position: absolute;
  top: 1px;
  left: 1rem;
}

.languageButton {
  width: 6rem;
  height: 6rem;
}

.subscribeNowBtn {
  display: block;
  width: 10rem;
  background: none;
  margin-left: 50%;
  transform: translate(-50%, 0);
}
.subscribeNowBtn img {
  width: 100%;
}

.demoButtonContainer {
  position: absolute;
  top: 0;
  display: block;
  margin-left: 50%;
  transform: translate(-50%, 0);
}

.demoButtonContainer img {
  width: 100%;
  height: 100%;
}

.staticDashboardProgramButtons {
  display: flex;
  align-items: center;
}

/* Media Queries */

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1024px) {
  .container {
    width: 56rem;
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 40rem;
  }
  .subscribeNowBtn {
    width: 8rem;
    align-self: center;
    margin-left: 50%;
    transform: translate(-50%, 0);
  }
}

@media screen and (max-width: 480px) {
  .container {
    width: 23rem;
    /* border: 1px solid green; */
  }
  .subscribeNowBtn {
    width: 10rem;
    margin-left: 50%;
    transform: translate(-50%, 0);
  }

  .staticDashboardProgramButtons {
    flex-direction: column;
  }
}
