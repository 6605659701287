@font-face {
  font-family: "TmonMonsori";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_two@1.0/TmonMonsori.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}
.container {
  font-family: "TmonMonsori";
  z-index: 3;
  height: 100%;
  width: 100%;
  background-color: #60c2f4;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f4f5f8;
  text-shadow: 0 1px #ccc, 0 2px #ccc, 0 3px #ccc, 0 4px #ccc, 0 5px #ccc,
    0 6px 0 transparent, 0 4px transparent, 0 6px transparent, 0 7px transparent,
    0 7px 7px rgba(0, 0, 0, 0.4);
}
.text_container {
  margin-top: -100px;
  text-align: center;
  font-size: 6vw;
  font-weight: bold;
  position: relative;
}
.jump {
  position: absolute;
  bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 0 1px #ccc, 0 2px #ccc, 0 3px #ccc, 0 4px #ccc, 0 5px #ccc,
    0 6px 0 transparent, 0 7px transparent, 0 8px transparent, 0 9px transparent,
    0 10px 10px rgba(0, 0, 0, 0.4);
}
.jump span {
  position: relative;
  top: 20px;
  display: inline-block;
  animation: bounce 0.3s ease infinite alternate;
}
.filler {
  opacity: 0;
}
@keyframes bounce {
  100% {
    top: -10px;
    text-shadow: 0 1px #ccc, 0 2px #ccc, 0 3px #ccc, 0 4px #ccc, 0 5px #ccc,
      0 6px #ccc, 0 7px #ccc, 0 8px #ccc, 0 9px #ccc,
      0 50px 25px rgba(0, 0, 0, 0.2);
  }
}
.jump span:nth-child(2) {
  animation-delay: 0.1s;
}
.jump span:nth-child(3) {
  animation-delay: 0.2s;
}
.jump span:nth-child(4) {
  animation-delay: 0.3s;
}
.jump span:nth-child(5) {
  animation-delay: 0.4s;
}
.jump span:nth-child(6) {
  animation-delay: 0.5s;
}
.jump span:nth-child(7) {
  animation-delay: 0.6s;
}
.jump span:nth-child(8) {
  animation-delay: 0.7s;
}
.jump span:nth-child(9) {
  animation-delay: 0.8s;
}
.jump span:nth-child(10) {
  animation-delay: 0.9s;
}
.jump span:nth-child(11) {
  animation-delay: 1s;
}
