.subText{
    font-size: 1.5rem;
    color: gray;
    text-align: center;
}

.alignCenter{
    text-align: center;
}

.logoText
{
    font-size: 24px;
    margin-top: 15px;
    font-family: 'michroma';
}

.signupBanner{
    font-size: 36px;
    margin-bottom: 27px;
    font-family: 'sensei';
    color: white;
    line-height: 1;
    text-align: center;
}


.signupBannerGrey{
    font-size: 36px;
    margin-bottom: 27px;
    font-family: 'sensei';
    color: grey;
    line-height: 1;
    text-align: center;
}

.secondSectionTitle{
    color:grey; 
    font-size:36px;
}

.secondSectionSubTitle{
    color:grey; 
    font-size:18px;
}


.mainActionButton{
    margin:12px;
    border-radius:30px; 
    font-family:sensei; 
    font-size:36px; 
    border:0; 
    background: 
    linear-gradient(90deg, #fdc830, #f37335);
}

.clubhouseSchedule{
    color: #2b2b2b; font-weight: 700;margin-bottom: 7px; line-height:1.5 !important;
}

.programSchedule{
    color: #2b2b2b; font-size: 24px;margin-bottom: 15px;font-weight: 700;margin-bottom: 7px; line-height:1.5 !important;
}

.techInfoTitle{
    color: #2b2b2b; font-size: 36px;margin-bottom: 15px;font-weight: 700;margin-bottom: 7px; line-height:1.5 !important;
}

.blackTxtColor{
    color:black;
}

.myDiv{
    display:none; 
    height:auto;
}

