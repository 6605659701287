.PatternMatchButtonContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.start_activity_btn {
  margin-left: 50%;
  margin-top: 5rem;
  transform: translate(-50%);

  background-color: #f8f8f8;
  border: 1px solid #f8f8f8;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1.5rem;
  font-weight: 500;
  color: #000;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.PatternMatch {
  background-color: skyblue;
  width: 100%;
  height: 100%;
  position: relative;
}

.closeBtn {
  position: absolute;
  top: 2rem;
  right: 2rem;
  color: white;
  font-size: 2rem;
  cursor: pointer;
}

.scoreCard {
  padding: 3rem 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.6rem;
}

.round_count_wrapper {
  display: flex;
  width: min-content;
  position: absolute;
  top: 2rem;
  gap: 1rem;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
  font-size: 3rem;
}

.questionContainer {
  margin: 4rem 0 0 0;
  display: flex;
  justify-content: space-around;
}

.question img {
  width: 20rem;
  border-radius: 1rem;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25) inset,
    0 5px 10px 5px rgba(0, 0, 0, 0.25);
}

.answer {
  width: 20rem;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25) inset,
    0 5px 10px 5px rgba(0, 0, 0, 0.25);
  transition: ease 0.5s;
}

.optionsContainer {
  margin: 3rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.optionsContainer img {
  width: 8rem;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25) inset,
    0 5px 10px 5px rgba(0, 0, 0, 0.25);
  border-radius: 1rem;
}

.optionDragTransition {
  transition: ease 1s;
}

.displayNone {
  display: none;
}

.visibilityHidden {
  visibility: hidden;
}

.correctAnsStyles {
  width: 100%;
  height: 100%;
}

.correctAnsDiv {
  width: 30rem;
}

/* Media Queries for Responsive Design */

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1024px) {
  .questionContainer {
    margin: 8px 0 0 0;
  }
  .question img {
    width: 8rem;
  }
  .answer {
    width: 8rem;
  }
  .correctAnsDiv {
    width: 18rem;
  }
  .round_count_wrapper {
    top: 8px;
  }
}

@media screen and (max-width: 768px) {
  .questionContainer {
    margin: 8px 0 0 0;
  }
  .question img {
    width: 8rem;
  }
  .answer {
    width: 8rem;
  }
  .correctAnsDiv {
    width: 18rem;
  }
  .round_count_wrapper {
    top: 8px;
  }
}

@media screen and (max-width: 480px) {
}
