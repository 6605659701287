.tncModalBackground {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
  background-color: #1f2d3da4;
  display: flex;
  justify-content: center;
}

.tncModalContainer {
  z-index: inherit;
  width: 30rem;
  top: 0;
  left: 5rem;
  height: 15rem;
  background-color: white;
  color: grey;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 4rem;
  padding: 1rem;
  border-radius: 2rem;
}

.closeModalBtn {
  color: white;
  padding: 4px 8px;
  border-radius: 8px;
  height: fit-content;
  width: fit-content;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.closeModalBtn i {
  font-size: 2rem;
}

.image_container {
  display: flex;
  flex-direction: column;
}

.start_session_btn {
  background-color: #1f2d3d;
  color: white;
  padding: 4px 8px;
  border-radius: 8px;
  height: fit-content;
  width: fit-content;
  cursor: pointer;
}
