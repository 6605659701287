.couponContainer {
  width: 22rem;
  position: relative;
  height: fit-content;
}
.coupon_applied_container {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}
.coupon_label {
  font-size: 1rem;
  margin-bottom: 5px;
  background-color: rgba(255, 255, 255, 0.6);
  width: fit-content;
  padding: 2px 4px;
  border-radius: 5px;
}
.coupon_input {
  padding: 7px;
  border-radius: 10px;
  border: none;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.coupon_input:focus {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.coupon_button {
  /* background-color: #dc3545; */
  background-color: #344966;
  padding: 8px 8px;
  color: white;
  margin-left: 4px;
  border-radius: 15px;
  font-weight: bold;
  font-size: 1rem;
}
.coupon_wrapper {
  background-color: white;
  text-align: center;
  min-width: min-content;
  width: calc(25vw + 40px);
  border-radius: 15px;
  padding: 20px 0px;
}
.bar {
  width: 20px;
  height: 3px;
  margin-left: auto;
  margin-right: auto;
  background-color: #282c3f;
  border-radius: 5px;
  margin-top: 10px;
}
.coupon_content {
  padding: 10px 30px;
  color: #7e808c;
  font-weight: bold;
}
.giftbox_image {
  margin-top: -60px;
  width: 75px;
}
.coupon_heading {
  margin-top: 5px;
  font-size: 1rem;
}
.coupon_name {
  font-size: 1rem;
  margin-top: 1rem;
  font-weight: bold;
  color: #686b78;
  width: 100%;
}
.coupon_amount {
  margin-top: 30px;
  padding: 5px;
  font-weight: bolder;
  font-size: 2.5rem;
  color: black;
}
hr {
  background-color: #939393;
}
.button {
  /* border-radius: 50px; */
  background-color: white;
  color: royalblue;
  font-weight: bold;
  font-size: 17px;
  width: 100%;
  padding: 0px 20px;
  letter-spacing: 1px;
}
@media only screen and (max-width: 700px) {
  .couponContainer {
    display: flex;
    flex-direction: column;
    margin-top: 4vh;
  }
  .giftbox_image {
    margin-top: -60px;
    width: 50px;
  }
  .coupon_wrapper {
    width: calc(75vw + 20px);
  }
}
