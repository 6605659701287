.rtt_online_program {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: fit-content;
  gap: 1rem;
  /* background-color: #ecf9fe; */
}
.button_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.demo_video_btn {
  width: 10rem;
  align-self: center;
  padding: 4px 8px;
  margin: 0;
  background-color: #0aa9e5;
  color: white;
  border-radius: 4px;
}

.demo_video_btn:hover {
  background-color: #0aabe5ea;
  color: white;
}

.footer_tab_bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 12;
  background-color: #0aa9e5;
  padding: 12px 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 2rem;
  border-radius: 8px;
}

.footer_price_container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.footer_price_container p:nth-child(1) {
  text-decoration: line-through;
}

.footer_price_container p:nth-child(2) {
  font-size: 1.2rem;
}

.footer_price_container p:nth-child(3) {
  font-size: 1.4rem;
}

.line_through {
  text-decoration: line-through;
}

.footer_payment_button {
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.2rem;
  background-color: transparent;
}

.footer_payment_button:hover {
  color: #fff;
}

.footer_total_selected_program {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer_total_selected_program p {
  color: white;
  font-size: 1rem;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
}

.footer_total_price_taxes {
  font-size: 12px;
}

.footer_selected_teacher {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer_selected_teacher p {
  color: white;
  font-size: 1rem;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
}

.footer_selected_teacher_name {
  align-self: center;
}

.topic_cards_container_twelve {
  margin: 0 0 5rem 0;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
}

.topic_cards_container_four {
  margin: 0 0 5rem 0;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
}

.topic_cards_container_one {
  margin: 0 0 5rem 0;
  display: grid;
  width: 100%;
  /* grid-template-columns: 1fr 1fr; */
  justify-items: center;
}
.coupon_button {
  width: 20rem;
  background-color: #f32170;
  font-weight: bold;
  font-size: 1rem;
  padding: 5px;
  color: white;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.tabs_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tabs_container {
  display: flex;
  justify-content: space-around;
  background-color: #e9e9e9;
  border-radius: 7px;
  color: #57606a;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  margin-top: 1rem;
  align-self: center;
}

.rtt_demo_video_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.activity_start_btn {
  cursor: pointer;
  width: 25%;
  animation: pulse 3s infinite;
  margin-top: 1rem;
}
.tabs {
  cursor: pointer;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 5rem;
}

.tabs_para_book {
  font-size: 12px;
}
.tabs_para {
  margin-bottom: 0px;
  font-size: 1.4rem;
  font-weight: 600;
}
.selected_tab {
  border: #85c7e0 solid 2px;
  border-radius: 7px;
  background-color: #ffffff;
}
.selected_tab .tabs_para {
  background: linear-gradient(to right, #f32170, #ff6b08, #cf23cf);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.vertical_line {
  min-height: 70%;
  max-height: 100vh;
  margin: 0 4px;
  border: 0.5px solid grey;
  align-self: center;
}

/* Media Queries */

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1024px) {
  .topic_cards_container_twelve {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .footer_tab_bar {
    margin: 0 8px;
    padding: 8px 1rem;
  }
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 480px) {
  .activity_start_btn {
    width: 45%;
    margin-top: 2rem;
  }
}
