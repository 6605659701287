.activity_progress_bar_container {
  width: 50%;
  top: 0;
  left: 55%;
  transform: translate(-50%, 0);
}

.activity_progress_bar_container {
  width: 40%;
  position: absolute;
  top: 0;
  left: 55%;
  transform: translate(-50%, 0);
  display: flex;
  justify-content: center;
  gap: 1rem;
  z-index: 1;
  padding: 4px;
}

.activity_progress_bar {
  height: 100%;
  width: 60%;
  position: relative;
}

.community_button {
  background-color: #9b2ece;
  color: white;
  padding: 6px 10px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  height: fit-content;
}

.community_button:hover {
  background-color: #b464ed;
  color: white;
}

.bronze-medal {
  width: 2rem;
  position: absolute;
  top: 0;
  left: 33%;
  transform: translate(-100%, 0);
}

.silver-medal {
  width: 2rem;
  position: absolute;
  top: 0;
  left: 66%;
  transform: translate(-100%, 0);
}

.gold-medal {
  width: 2.1rem;
  position: absolute;
  top: 0;
  left: 100%;
  transform: translate(-100%, 0);
}

.activity-button {
  cursor: pointer;
  position: relative;
  /* color: #f4f5f8; */
  color: #f4f5f8;
  /* width: 83%; */
  width: 150px;
  transition: all 0.5s;
  /* flex-basis: 150px;
    flex-grow: 0;
    flex-shrink: 0; */
  /* transform: translate(-50%); */
  /* animation: pulse 5s infinite; */
}
