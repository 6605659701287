.excercise {
  cursor: pointer;
  position: absolute;
  width: 10%;
}
.popup_wrapper {
  text-align: center;
  background-color: #cfd1cd;
  border-radius: 10px;
  padding: 5px;
  animation-duration: 1s;
}
.para {
  font-size: 1.3rem;
  font-weight: semibold;
  font-family: sensei;
}
.excercise-current {
  width: 11%;
}
.current-panda {
  position: absolute;
  width: 80%;
  top: -45%;
  left: -30%;
}

.excercise-1 {
  top: 58%;
  left: 4%;
}

.excercise-2 {
  top: 20%;
  left: 7%;
}

.excercise-3 {
  top: 52%;
  left: 20%;
}

.excercise-4 {
  top: 22.5%;
  left: 37%;
}

.excercise-5 {
  top: 52%;
  left: 53%;
}

.excercise-6 {
  top: 27%;
  left: 70%;
}

.excercise-7 {
  top: 48%;
  left: 87%;
}

.excercise-stars {
  display: flex;
  width: calc(100% / 3);
  margin-top: -26%;
  position: relative;
}

.excercise-star-one {
  transform: rotate(-45deg);
  margin-bottom: 1rem;
}

.excercise-star-three {
  transform: rotate(45deg);
  margin-bottom: 1rem;
}

.excercise-number {
  font-family: "Nunito Black", sans-serif;
  position: absolute;
  top: 47.5%;
  right: 50%;
  transform: translate(50%, -50%);
  font-size: 8.6vh;
  font-weight: 900;
  color: white;
  text-decoration: none;
}
.excercise-number-rtt {
  font-family: "Nunito Black", sans-serif;
  position: absolute;
  top: 47.5%;
  right: 50%;
  transform: translate(50%, -50%);
  font-size: 2.8vh;
  font-weight: 900;
  color: white;
  text-decoration: none;
  text-align: center;
  padding: 3px;
  border-radius: 5px;
}

.end-bubble {
  animation: pulse 3s infinite;
}

@-webkit-keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

/* Media Queries */

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1024px) {
  .para {
    font-size: 10px;
    line-height: 10px;
  }
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 480px) {
}
@media screen and (max-height: 700px) {
  .excercise-number {
    font-size: 6.5vh;
  }
}
@media screen and (max-height: 500px) {
  .excercise-number {
    font-size: 10vh;
  }
}
