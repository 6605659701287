.community_footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 5rem;
  /* background-color: #9cdeff; */
  background-color: #ffffff;
  z-index: 10;
}

.community_footer__community_button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-weight: 600;
  height: 100%;
  color: white;
}

.community_footer__my_canvas_button_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.community_footer__my_canvas_button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-weight: 600;
  color: grey;
  background-color: transparent;
  cursor: pointer;
}

.community_footer__my_canvas_button:hover {
  color: #fc5200;
}

.community_footer__my_canvas_button p,
i {
  font-size: 1rem;
}

.activeTab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  background-color: transparent;
  font-weight: 800;
  color: #fc5200;
  cursor: pointer;
}

.activeTab:hover {
  color: #fc5200;
}

.activeTab i {
  font-weight: 600;
  color: #fc5200;
}
.activeTab p {
  font-size: 1rem;
  font-weight: 600;
  color: #fc5200;
}

.community_footer__start_activities_button {
  background-color: #fc5200;
  padding: 14px 16px;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 1rem;
  z-index: 10;
  border: 12px solid white;
}
.community_footer__start_activities_button p {
  color: white;
  font-size: 14px;
  font-weight: 600;
}

.start_activities_button p {
  color: white;
  font-size: 14px;
  font-weight: 600;
}

.start_activities_button p {
  color: white;
  font-size: 12px;
  font-weight: 600;
}

/* Media Queries */

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1024px) {
  .community_footer {
    gap: 8rem;
  }
  .start_activities_button p {
    font-size: 10px;
    font-weight: 500;
  }
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 480px) {
}
