.free_downloadables_page {
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.back_button_container {
  width: 4rem;
  position: absolute;
  top: 1rem;
  left: 1rem;
  cursor: pointer;
}

.images_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}

.free_downloadables_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  padding: 1rem 2rem 1rem 1rem;
}

.downloadables_title {
  color: #f37335;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  text-align: left;
}

.no_of_activities {
  font-family: "Roboto", sans-serif;
  color: #1360a0;
  font-size: 1.2rem;
}

.downloadables_description {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.7;
}

.like_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.like_count {
  font-size: 1.2rem;
  color: #808080;
}

.like_icon {
  font-size: 1.2rem;
  color: #808080;
}

.action_btn_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}

.action_btn_download {
  background-color: #1360a0;
  padding: 8px 1rem;
  border-radius: 1rem;
  color: white;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 4px;
}

.action_btn_download:hover {
  filter: contrast(1.6);
  color: white;
}

.action_btn_share {
  border: 2px solid grey;
  padding: 8px 1rem;
  color: grey;
  border-radius: 1rem;
  background-color: transparent;
  font-weight: 600;
}

.action_btn_like {
  border: 2px solid grey;
  padding: 8px 1rem;
  color: grey;
  border-radius: 1rem;
  background-color: transparent;
  font-weight: 600;
}

.main_image_container {
  width: 40%;
}

.main_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease-in-out;
}

.sub_images_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.sub_image {
  width: 5rem;
  cursor: pointer;
  filter: blur(0.5px);
}

/* Media Queries */

@media screen and (max-width: 1280px) {
  .main_image_container {
    width: 50%;
  }
}

@media screen and (max-width: 1024px) {
  .free_downloadables_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    padding: 1rem 2rem 1rem 1rem;
  }

  .downloadables_description {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {
  .free_downloadables_page {
    grid-template-columns: 1fr;
  }
  .images_container {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .downloadables_description {
    font-size: 14px;
  }

  .back_button_container {
    width: 3rem;
  }
}
