.signupBanner {
  font-size: 36px;
  margin-bottom: 27px;
  font-family: "sensei";
  color: white;
  line-height: 1;
  text-align: center;
}
.signUpDiv {
  background: linear-gradient(90deg, #7fb1da, #1360a0);
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.loginNowBtn {
  font-family: sensei;
  font-size: 24px;
  width: 33%;
  background-color: white;
  color: #f37335;
  font-weight: 900;
  z-index: 9;
  border: 0;
  border-radius: 25px;
}

/* background: linear-gradient(90deg, #fdc830, #f37335); */
