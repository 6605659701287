.activity_wrapper {
  position: relative;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  background-color: azure;
}
.activity_wrapper_end {
  transition: all 0.3s;
  position: absolute;
  font-size: 1.5rem;
  right: 2px;
  top: 2px;
  color: white;
  z-index: 2;
  cursor: pointer;
  border-radius: 50%;
  background-color: #ef9a9a;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.activity_wrapper_end i {
  transition: all 0.3s;
}
.activity_wrapper_end:hover {
  background-color: white;
}
.activity_wrapper_end:hover i {
  color: #ef9a9a;
}
.activity_wrapper_custom {
  padding: 20px;
  background-color: aliceblue;
  /* background: url(/public/assets/activity/christmasbglow.png) no-repeat center
    center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
  position: relative;
}
.activity_background {
  width: 100%;
  height: 100%;
  background-color: aliceblue;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  border-radius: 10px;
}
.activity_background::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
/* z-index: -1; */

@media only screen and (max-width: 992px) {
  .activity_wrapper_custom {
    padding: 0px;
  }
}
