.subText {
  font-size: 1.5rem;
  color: gray;
  text-align: center;
}

.braincellsLogoContainer {
  border: 1px solid red;
  width: 4rem;
}

.linearGradient {
  background: linear-gradient(90deg, #7fb1da, #1360a0);
}

.braincellsLogo {
  border: 1px solid green;
}
.subTextList {
  font-size: 1.2rem;
  color: #1360a0;
  text-align: center;
  margin: 0;
}

.rttLandingImg {
  width: 26rem;
}

.rttSmallText {
  color: #1360a0;
  font-size: 1.2rem;
  font-family: "sensei";
}

.rttHeading {
  color: #1360a0;
  font-family: "sensei";
  font-size: 3rem;
}

.alignCenter {
  text-align: center;
}

.logoText {
  font-size: 24px;
  margin-top: 15px;
  font-family: "michroma";
}

.signupBanner {
  font-size: 36px;
  margin-bottom: 27px;
  font-family: "sensei";
  color: white;
  line-height: 1;
  text-align: center;
}

.signupBannerGrey {
  font-size: 36px;
  margin-bottom: 27px;
  font-family: "sensei";
  color: grey;
  line-height: 1;
  text-align: center;
}

.secondSectionTitle {
  color: grey;
  font-size: 36px;
}

.secondSectionSubTitle {
  color: grey;
  font-size: 18px;
}

.mainActionButton {
  margin: 12px;
  border-radius: 30px;
  font-family: sensei;
  font-size: 36px;
  border: 0;
  background: linear-gradient(90deg, #fdc830, #f37335);
}

.strikethroughPrice {
  text-decoration: line-through;
  font-size: 1rem;
  color: #155799;
}

.priceContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}

.clubhouseSchedule {
  color: #2b2b2b;
  font-weight: 700;
  margin-bottom: 7px;
  line-height: 1.5 !important;
}

.programSchedule {
  color: #2b2b2b;
  font-size: 24px;
  margin-bottom: 15px;
  font-weight: 700;
  margin-bottom: 7px;
  line-height: 1.5 !important;
}

.techInfoTitle {
  color: #2b2b2b;
  font-size: 36px;
  margin-bottom: 15px;
  font-weight: 700;
  margin-bottom: 7px;
  line-height: 1.5 !important;
}

.blackTxtColor {
  color: black;
}

.myDiv {
  display: none;
  height: auto;
}

/* Media Queries */

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 480px) {
}
