@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap");

.teacher_card {
  /* height: 35rem; */
  min-height: fit-content;
  background-color: #faf9f6;
  width: 20rem;
  display: flex;
  flex-direction: column;
  border-radius: 2rem;
  margin-left: 1rem;
  position: relative;
  padding: 15px 0px;
  cursor: pointer;

  /* Box Shadow */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.teacher_card__image_container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px 0px;
}

.teacher_card__image {
  width: 10rem;
}

.teacher_card__details_container {
  width: 100%;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.teacher_card__name_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.teacher_card__name {
  color: #252525;
  font-weight: 700;
  font-size: 1.4rem;
  font-family: "Lato", sans-serif;
}

.teacher_card__title {
  color: #26235c;
}

.teacher_card__description_container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 8px;
}

.teacher_card__description {
  color: #252525;
  font-size: 14px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  text-align: center;
}

.teacher_card__timing {
  background-color: #26235c;
  color: white;
  padding: 4px 12px;
  border-radius: 12px;
  /* width: fit-content; */
}

.teacher_card__social_media_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 1rem;
}

.teacher_card__social_media_container i {
  font-size: 1.4rem;
  color: #26235c;
}

.braincells_logo {
  width: 2rem;
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.teacher_card__timings {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
}

.teacher_card__timings__day {
  font-size: 12px;
  font-weight: 500;
  margin-top: 0;
  line-height: 1.2rem;
  margin-left: 8px;
}

.teacher_card__timings__day_title {
  font-size: 1rem;
  font-weight: 600;
  margin-top: 0;
  color: #26235c;
  border: 2px solid #26235c;
  padding: 4px 8px;
  border-style: dashed;
}

.teacher_card__radio_toolbar input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.select_teacher_button {
  display: inline-block;
  background-color: #ddd;
  padding: 8px;
  font-family: sans-serif, Arial;
  font-size: 16px;
  border: 2px solid #444;
  border-radius: 4px;
  cursor: pointer;
}

.teacher_card__radio_toolbar input[type="radio"]:checked + label {
  background-color: #bfb;
  border-color: #4c4;
}
