.question_two {
  margin-top: 5rem;
}

.modalQuestions {
  font-weight: 500;
  font-size: 24px;
}

.dropdown_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
}

select {
  padding: 4px;
  margin-top: 8px;
  border-radius: 4px;
}

select option {
  border: 1px solid red;
}
