.info_icon_container {
  position: fixed;
  bottom: 50%;
  right: 2.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: #0d8ecc;
  font-family: Sensei;
  color: white;
  cursor: pointer;
}

.video_wrapper {
  display: flex;
  background-color: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 20;
}
.video_container {
  width: 80%;
  height: 80%;
  position: relative;
}

.loading_container {
  width: 80%;
  height: 80%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  background-color: white;
  border-radius: 25px;
}
.video_close_button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  border-radius: 1rem;
  padding: 4px 8px;
}
.video_close_button:hover {
  color: red;
}

.hide_tutorial_btn {
  border: 2px solid #0d8ecc;
  font-family: Sensei;
  color: #0d8ecc;
  font-family: Sensei;
  background-color: transparent;
  padding: 8px 1rem;
  cursor: pointer;
  border-radius: 0.5rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.exe-number-wrapper {
  display: flex;
  background-color: white;
  border-radius: 50%;
  border: 3.5px solid;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 4vw;
  height: 4vw;
  box-shadow: 0 2px 4px darkslategray;
  position: fixed;
  bottom: 10%;
  right: 3rem;
  border-color: #0d8ecc;
}
.current-exe-number {
  font-size: 3vw;
  font-family: sensei;
  display: flex;
  align-items: center;
  color: #0d8ecc;
}

.tutorial_video {
  position: fixed;
  bottom: 10%;
  right: 1rem;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tutorial_video_container {
  background: rgb(20, 111, 181);
  background: linear-gradient(
    180deg,
    rgba(20, 111, 181, 1) 30%,
    rgba(0, 212, 255, 1) 100%
  );
  font-family: Sensei;
  color: white;
  cursor: pointer;
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 270px;
  height: 150px;
}

.play_button_container {
  /* margin-bottom: 1.4rem; */
}

.play_button_container i {
  font-size: 2.6rem;
}

.tutorial_title {
  font-size: 1.5rem;
  line-height: 2rem;
  color: white;
}

.closeBtn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  opacity: 50%;
}

.closeBtn i {
  font-size: 1.6rem;
}

.tutorial_text {
  font-size: 1rem;
  /* line-height: 1rem; */
  color: white;
}

/* Media Queries */

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1024px) {
  .show_tutorial_btn {
    padding: 8px;
  }

  .hide_tutorial_btn {
    padding: 8px;
  }

  .tutorial_video_container {
    width: 210px;
    height: 120px;
  }
  .tutorial_title {
    font-size: 1.2rem;
    line-height: 2rem;
    color: white;
  }
  .exe-number-wrapper {
    right: 2.3rem;
  }
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 480px) {
}
