.tncModalBackground {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
}

.tncModalContainer {
  z-index: inherit;
  width: 30rem;
  top: 0;
  left: 5rem;
  height: 15rem;
  color: grey;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 1rem;
  border-radius: 2rem;
}

.closeModalBtn {
  background-color: skyblue;
  color: white;
  padding: 4px 8px;
  border-radius: 8px;
}

.card {
  width: 16rem;
  height: 17rem;
  border-radius: 8px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 4px;
  cursor: pointer;
}

.card__title {
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
}

.card__strengths_title {
  font-size: 1.3rem;
  font-weight: 600;
  color: #000;
}

.card__body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-around;
  align-items: center;
}

.characteristics_container ul {
  color: black;
}

.closeBtn {
  position: absolute;
  top: 2rem;
  right: 30%;
  cursor: pointer;
  color: black;
}

.closeBtn i {
  font-size: 2rem;
}

/* Media Queries */

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 768px) {
  .closeBtn {
    top: 1rem;
    right: 1rem;
  }
}

@media screen and (max-width: 480px) {
}
