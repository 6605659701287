.clubhouse {
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}

.container {
  width: 70rem;
  height: 80%;
  display: flex;
  border-radius: 15px;
  flex-wrap: wrap;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  text-align: center;
  width: 70rem;
  z-index: 1;
  position: relative;
}

.currSlideNumber {
  position: absolute;
  top: -8px;
  font-size: 1.4rem;
  font-weight: 600;
  color: #248dff;
}

.youtubeIframeContainer {
  height: 30rem;
}

/* Media Queries */

@media screen and (max-width: 1280px) {
  .container {
    width: 60rem;
  }
}

@media screen and (max-width: 1023px) {
  .container {
    width: 32rem;
  }
  .youtubeIframeContainer {
    height: 12rem;
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 40rem;
  }
  .youtubeIframeContainer {
    height: 14rem;
  }
}

@media screen and (max-width: 480px) {
  .container {
    width: 35rem;
  }
}
