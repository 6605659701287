.mainContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "leftContainer rightContainer";
}

.masterclassHeading {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 1rem 0;
}

.leftContainer {
  grid-area: leftContainer;
}

.rightContainer {
  grid-area: rightContainer;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.stepsContainer {
  background-color: #ffdc83;
  height: 10rem;
  width: 80%;
  margin: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border-radius: 2rem;
}

.stepsHeading {
  font-size: 1.2rem;
  font-weight: 600;
  color: black;
}

.trialBtnText {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: white;
  font-family: "Athletics-Black" !important;
}

.trialActivitiesBtn {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: 3rem;
  padding: 42px 30px;
  border-radius: 2.25rem;
  background-color: rgb(245, 184, 3);
  border: none;
  width: 15rem;
  box-shadow: rgb(242 145 4) 0px 4px;
  transition: all 0.2s ease 0s;
  margin-bottom: 20px;
  cursor: pointer !important;
}

.programBtn {
  height: 64px;
  padding: 24px;
  border-radius: 16px;
  background-color: rgb(255, 247, 219);
  border: 2px solid rgb(255, 232, 150);
  width: max-content;
  box-shadow: rgb(255 232 150) 0px 4px;
  display: flex;
  justify-content: space-around;
  -webkit-box-align: center;
  align-items: center;
  transition: all 0.2s ease 0s;
}

.programBtnText {
  display: block;
  color: rgba(0, 0, 0, 0.8);
  text-align: left;
  font-style: normal;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  font-family: "Athletics-Black" !important;
}

.trialActivitiesBtn:hover,
.programBtn:hover,
.subscribe-btn:hover {
  box-shadow: 0px 0px;
  transform: translateY(4px);
}

.trialBtnContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}

.videoImgHolder {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.subscriptionContainer {
  display: flex;
  gap: 1rem;
  margin-left: 1rem;
}

.singleSubscriptionContainer {
  background-color: #f0f3f7;
  width: 12rem;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  position: relative;
}

.braincellsOneMonthImg {
  border-radius: 50%;
}
.stepOneContainer {
  background-color: #ffdc83;
  height: 10rem;
  width: 90%;
  margin: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border-radius: 2rem;
}
.mostPopularImg {
  width: 6rem;
  position: absolute;
  top: 0;
  right: 0;
}

.calendlyContainer {
}

/* Media Queries */

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1024px) {
  .mainContainer {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      "leftContainer leftContainer"
      "rightContainer rightContainer";
  }
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 480px) {
}
