.thankyou {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .thankyouTitle {
    font-family: sensei;
    font-size: 3rem;
    color: #7cb0df;
  }
  
  .thankyouEmoji {
    height: 7rem;
    width: 7rem;
  }
  
  @media only screen and (max-width: 1000px) {
    .thankyouTitle {
      font-size: 2rem;
    }
    .thankyouEmoji {
      height: 5rem;
      width: 5rem;
    }
  }
  
  @media only screen and (max-width: 700px) {
    .thankyouTitle {
      font-size: 1rem;
    }
    .thankyouEmoji {
      height: 3rem;
      width: 3rem;
    }
  }
  