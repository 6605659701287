.tncModalBackground {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: #1f2d3d;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.tncModalContainer {
  z-index: inherit;
  width: 100%;
  height: 100%;
  color: grey;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 1rem;
}

.tncContent {
  font-size: 2.5rem;
  color: #057dff;
}

.closeModalBtn {
  background-color: skyblue;
  color: white;
  padding: 4px 8px;
  border-radius: 8px;
}
