.single_post_page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f7fa;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 80vh;
}
