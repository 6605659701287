.page_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.table_wrapper {
  margin-bottom: 50px;
  /* background-color: antiquewhite; */
  padding: 5px;
  border-radius: 10px;
  background-color: #ecf9fe;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.button_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.topic_wrapper {
  margin-bottom: 20px;
  background-color: #c7eefd;
  /* border: solid 1px black; */
  padding: 8px;
  border-radius: 5px;
}
.table_container {
  padding: 20px;
}
.contact_anchor {
  color: blue;
}
.topic_info {
  margin-bottom: 10px;
  font-size: 1.1rem;
  font-weight: bold;
}
.topic_info_name {
  font-weight: bold;
}
.topic_info_button {
}
.topic_date {
  margin: 15px 0px;
  font-size: 1.4rem;
  font-weight: bold;
}
