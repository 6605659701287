.redeem_button {
  padding: 10px;
  border-radius: 50px;
  color: white;
  background-color: #ea4c4c;
  font-weight: bold;
  font-size: 1.1rem;
}
.redeem_button:hover {
  background-color: #ea4c4c;
  scale: 1.05;
  transition: all 100ms;
}
.countdown_text {
  font-size: 1.2rem;
  font-weight: 500;
  color: grey;
  margin-top: 5px;
}
.countdown_wrapper {
  display: flex;
  justify-content: center;
  gap: 30px;
  background-color: #f2f1ed;
  padding: 20px;
  padding-bottom: 30px;
  width: fit-content;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}
.discount_text {
  color: #3bb273;
}
.countdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.countdown_header {
  font-size: 0.85rem;
}
.countdown_body {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.countdown_body_sub {
  background-color: white;
  padding: 7px 20px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  font-weight: 400;
  color: #ea4c4c;
  font-size: 4.5rem;
  /* transition: transform 100ms;
    transform: all 100ms;
    animation-duration: 700ms; */
}
