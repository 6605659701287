/** @format */

.loader {
  position: absolute;
  min-height: 40px;
  border-radius: 10px;
  width: 100%;
  background-color: whitesmoke;
  background-image: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 40px,
    #72a0c1 40px,
    #72a0c1 80px
  );
  animation: animate 4s linear infinite;
}

@keyframes animate {
  from {
    background-position-x: 0;
  }
  to {
    background-position-x: 300px;
  }
}

.loader-container {
  position: relative;
  width: 100%;
  height: 100%;

  width: 300px;
}

.loader-contents-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.progressbar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* width: 50%; */
  height: 100px;
}
.box {
  position: static;
}

.loader-child {
  /* transform-origin: left top; */
  /* transform: rotate(180deg) translate(100%, -100%); */
  position: absolute;
  top: 0%;
  right: 0%;
  background-color: aliceblue;
  z-index: 300;
  height: 40px;
  width: 100%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 1500;
}

.loader-content-container {
  display: flex;
  align-items: flex-start;
  padding: 0.5rem;
  height: 8rem;
  z-index: 500;
  margin-top: 2rem;
  /* width: 30rem; */
  width: 300px;
}

.loader_text_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.loader-text-percentage {
  color: whitesmoke;
  font-size: 30px;
  position: relative;
  margin-top: 0.5rem;
  font-family: "sensei";
}
.loader-text {
  color: whitesmoke;
  font-size: 16px;
  margin-right: 5rem;
  margin-top: 1rem;
  font-family: "sensei";
}
