.logoText {
  font-size: 24px;
  margin-top: 15px;
  font-family: "michroma";
}

.braincellsLogo {
  width: 10rem;
}

.bgBubbles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.bgBubbles li {
  position: absolute;
  list-style: none;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-color: rgba(255, 255, 255, 0.15);
  bottom: -160px;
  -webkit-animation: square 25s infinite;
  animation: square 25s infinite;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
}
.bgBubbles li:nth-child(1) {
  left: 10%;
}
.bgBubbles li:nth-child(2) {
  left: 20%;
  width: 80px;
  height: 80px;
  border-radius: 100px;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-duration: 17s;
  animation-duration: 17s;
}
.bgBubbles li:nth-child(3) {
  left: 25%;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}
.bgBubbles li:nth-child(4) {
  left: 40%;
  border-radius: 100px;
  width: 60px;
  height: 60px;
  -webkit-animation-duration: 22s;
  animation-duration: 22s;
  background-color: rgba(222, 73, 73, 0.25);
}
.bgBubbles li:nth-child(5) {
  left: 70%;
}
.bgBubbles li:nth-child(6) {
  left: 80%;
  border-radius: 100px;
  width: 120px;
  height: 120px;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
  background-color: rgba(255, 255, 255, 0.2);
}
.bgBubbles li:nth-child(7) {
  left: 32%;
  width: 160px;
  height: 160px;
  border-radius: 100px;
  -webkit-animation-delay: 7s;
  animation-delay: 7s;
}
.bgBubbles li:nth-child(8) {
  left: 55%;
  width: 20px;
  height: 20px;
  border-radius: 100px;
  -webkit-animation-delay: 15s;
  animation-delay: 15s;
  -webkit-animation-duration: 40s;
  animation-duration: 40s;
}
.bgBubbles li:nth-child(9) {
  left: 25%;
  width: 10px;
  height: 10px;
  border-radius: 100px;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-duration: 40s;
  animation-duration: 40s;
  background-color: rgba(211, 70, 70, 0.3);
}
.bgBubbles li:nth-child(10) {
  left: 90%;
  width: 160px;
  height: 160px;
  border-radius: 100px;
  -webkit-animation-delay: 11s;
  animation-delay: 11s;
}
@-webkit-keyframes square {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-700px) rotate(600deg);
    transform: translateY(-700px) rotate(600deg);
  }
}
@keyframes square {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-700px) rotate(600deg);
    transform: translateY(-700px) rotate(600deg);
  }
}
