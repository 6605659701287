.activity_wrapper {
  height: 100%;
  width: 100%;
  background-color: white;
}
.slider_wrapper {
  position: fixed;
  top: 50%;
  left: 0%;
  height: 50px;
  width: 50px;
  background-color: rgba(0, 0, 0, 0.6);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 10;
  transform: translateY(-50%);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slider_wrapper i {
  font-size: 2rem;
}
.loader_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.menu_wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 3;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.menu {
  position: relative;
  padding: 30px;
  display: flex;
  height: fit-content;
  min-width: fit-content;
  width: 40%;
  background-color: white;
  /* border-top-left-radius: 10px; */
  /* border-bottom-left-radius: 10px; */
  border-bottom-right-radius: 10px;
  text-align: center;
  flex-direction: column;
}
.menu_close {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: absolute;
  padding: 4px;
  right: 0px;
  top: -20px;
  height: 20px;
  background-color: white;
}
.menu_close i {
  font-size: 1.2rem;
}
.select_lang label {
  font-size: 1.5rem;
  margin-bottom: 5px;
  color: #808080;
}
.lang_options_wrapper {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  justify-content: center;
}
.lang_button {
  padding: 10px;
  border-radius: 5px;
  background-color: #8dd54f;
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
}
.share_container {
  margin-top: 20px;
}
.share_container label {
  font-size: 1.3rem;
  margin-bottom: 5px;
  color: #808080;
}
.share_buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  justify-content: center;
}
