@keyframes ldio-18s9ayuergv {
  0% {
    background: #a2d895;
  }
  12.5% {
    background: #a2d895;
  }
  12.625% {
    background: #d4ecc9;
  }
  100% {
    background: #d4ecc9;
  }
}
.ldio-18s9ayuergv div {
  position: absolute;
  width: 40px;
  height: 40px;
  background: #d4ecc9;
  animation: ldio-18s9ayuergv 1s linear infinite;
}
.loadingio-spinner-blocks-4uebm1766j {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  /* background: #f1f2f3; */
}
.ldio-18s9ayuergv {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-18s9ayuergv div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */
