.single_notification {
  width: 24.36rem;
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 5px;
  border-radius: 5px;
  /* Box Shadow */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.single_notification__profile_pic {
  width: 3rem;
  height: 3rem;
}
