.payment_button {
  margin-top: 10px;
  margin-bottom: 15px;
  background-color: #17be88;
  color: white;
  border-radius: 10px;
  padding: 8px 16px;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
}
.payment_button:hover {
  scale: 1.1;
  transition: 0.15s ease-in-out;
}

.footer_payment_button {
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.2rem;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 8px;
}

.footer_payment_button p {
  color: #fff;
  font-weight: 600;
  font-size: 1.2rem;
}

.footer_payment_button:hover {
  color: #fff;
}

.teacher_profile {
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  margin: 0 0.5rem;
}
