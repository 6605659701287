.pricing_page_wrapper {
  --tag-width: 11px;
  --title-color: #24292f;
  --title-weight: bold;
  --title-size: 1.5rem;
  --price-size: 1.2rem;
  --rupees-size: 0.8rem;
  --subtitle-color: #57606a;
}

.point_container {
  display: flex;
  align-items: center;
}
.point_container i {
  color: green;
  margin-right: 10px;
}

.pricing_wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  justify-content: space-evenly;
  align-items: stretch;
  padding: 0px 7%;
}
.valid_until {
  font-size: 0.8rem;
  background-color: #008000;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 10px;
  color: white;
  margin: 0;
  padding: 4px 8px;
  position: absolute;
  top: 0;
  left: 0;
  font-weight: bold;
}
.expired_on {
  font-size: 0.8rem;
  border-bottom-right-radius: 2px;
  background-color: #ff6961;
  border-top-left-radius: 10px;
  color: white;
  margin: 0;
  padding: 4px 8px;
  position: absolute;
  top: 0;
  left: 0;
  font-weight: bold;
}
.card_inner_wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 10px;
}
.pricing_card_popular {
  /* height: 50vh; */
  width: 100%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  background-color: #0d74ec;
  display: flex;
  justify-content: center;
}
.popular_card_inner_wrapper {
  margin: var(--tag-width);
  margin-top: calc(var(--tag-width) * 2);
  border-radius: 5px;
  background-color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.pricing_card {
  margin: calc(var(--tag-width) * 2);
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #ffffff;
  position: relative;
}
.pricing_head {
  text-align: center;
  background-color: #ffffff;
  border-radius: 5px;
}
.pricing_head .title {
  color: var(--title-color);
  font-weight: var(--title-weight);
  font-size: var(--title-size);
  margin: 10px 0px;
}
.pricing_head .subtitle {
  color: var(--subtitle-color);
  font-size: 0.9rem;
  line-height: 1.25;
  width: 100%;
  margin: 5px 0px;
  white-space: pre-wrap;
}
.price {
  display: flex;
  flex-direction: column;
  color: #575757;
}
.pricing_head .price {
  font-size: var(--price-size);
  font-weight: bold;
}
.pricing_head .price i {
  margin-right: 3px;
  font-size: var(--rupees-size);
}
.price_button {
  background: linear-gradient(#3c3f42, #1b1f23);
  color: white;
  border-radius: 5px;
  width: 90%;
  height: 40px;
  font-weight: bold;
  margin-top: 10px;
}
.pricing_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f6f8fa;
  border-radius: 10px;
}
.points_wrapper {
  width: 90%;
  margin: 20px 0px;
}

.popular_tag {
  color: white;
  z-index: 2;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 600;
}
.pricing_text i {
  margin-right: 5px;
  color: green;
}
.pricing_text {
  font-weight: 600;
}
.strikeout_price {
  font-size: 0.9rem;
  text-decoration: line-through;
  font-weight: 400;
}
.temp {
  display: block;
}
@media only screen and (max-width: 992px) {
  .pricing_card_popular {
    height: auto;
  }
  .pricing_wrapper {
    padding: 0%;
  }
}
.temp .pricing_card {
  width: fit-content;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 700px) {
  .pricing_wrapper {
    flex-wrap: wrap;
    padding: 0px 10px;
  }
}
