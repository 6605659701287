.loadingio-spinner-ellipsis-obdvglnfqi {
  --bgcolor: blue;
}
@keyframes ldio-tlng32g1cug {
  0% {
    transform: translate(12px, 80px) scale(0);
  }
  25% {
    transform: translate(12px, 80px) scale(0);
  }
  50% {
    transform: translate(12px, 80px) scale(1);
  }
  75% {
    transform: translate(80px, 80px) scale(1);
  }
  100% {
    transform: translate(148px, 80px) scale(1);
  }
}
@keyframes ldio-tlng32g1cug-r {
  0% {
    transform: translate(148px, 80px) scale(1);
  }
  100% {
    transform: translate(148px, 80px) scale(0);
  }
}
@keyframes ldio-tlng32g1cug-c {
  0% {
    background: var(--bgcolor);
  }
  25% {
    background: var(--bgcolor);
  }
  50% {
    background: var(--bgcolor);
  }
  75% {
    background: var(--bgcolor);
  }
  100% {
    background: var(--bgcolor);
  }
}
.ldio-tlng32g1cug div {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transform: translate(80px, 80px) scale(1);
  background: var(--bgcolor);
  animation: ldio-tlng32g1cug 1.923076923076923s infinite
    cubic-bezier(0, 0.5, 0.5, 1);
}
.ldio-tlng32g1cug div:nth-child(1) {
  background: var(--bgcolor);
  transform: translate(148px, 80px) scale(1);
  animation: ldio-tlng32g1cug-r 0.4807692307692307s infinite
      cubic-bezier(0, 0.5, 0.5, 1),
    ldio-tlng32g1cug-c 1.923076923076923s infinite step-start;
}
.ldio-tlng32g1cug div:nth-child(2) {
  animation-delay: -0.4807692307692307s;
  background: var(--bgcolor);
}
.ldio-tlng32g1cug div:nth-child(3) {
  animation-delay: -0.9615384615384615s;
  background: var(--bgcolor);
}
.ldio-tlng32g1cug div:nth-child(4) {
  animation-delay: -1.4423076923076923s;
  background: var(--bgcolor);
}
.ldio-tlng32g1cug div:nth-child(5) {
  animation-delay: -1.923076923076923s;
  background: var(--bgcolor);
}
.loadingio-spinner-ellipsis-obdvglnfqi {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: none;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
.ldio-tlng32g1cug {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}
.ldio-tlng32g1cug div {
  box-sizing: content-box;
}

/* @keyframes ldio-yzh88j6wa7g {
  0% {
    transform: translate(12px, 80px) scale(0);
  }
  25% {
    transform: translate(12px, 80px) scale(0);
  }
  50% {
    transform: translate(12px, 80px) scale(1);
  }
  75% {
    transform: translate(80px, 80px) scale(1);
  }
  100% {
    transform: translate(148px, 80px) scale(1);
  }
}
@keyframes ldio-yzh88j6wa7g-r {
  0% {
    transform: translate(148px, 80px) scale(1);
  }
  100% {
    transform: translate(148px, 80px) scale(0);
  }
}
@keyframes ldio-yzh88j6wa7g-c {
  0% {
    background: inherit;
  }
  25% {
    background: inherit;
  }
  50% {
    background: inherit;
  }
  75% {
    background: inherit;
  }
  100% {
    background: inherit;
  }
}
.ldio-yzh88j6wa7g div {
  position: absolute;
  width: 3vw;
  height: 3vw;
  border-radius: 50%;
  transform: translate(80px, 80px) scale(1);
  background: inherit;
  animation: ldio-yzh88j6wa7g 1s infinite cubic-bezier(0, 0.5, 0.5, 1);
}
.ldio-yzh88j6wa7g div:nth-child(1) {
  background: inherit;
  transform: translate(148px, 80px) scale(1);
  animation: ldio-yzh88j6wa7g-r 0.25s infinite cubic-bezier(0, 0.5, 0.5, 1),
    ldio-yzh88j6wa7g-c 1s infinite step-start;
}
.ldio-yzh88j6wa7g div:nth-child(2) {
  animation-delay: -0.25s;
  background: inherit;
}
.ldio-yzh88j6wa7g div:nth-child(3) {
  animation-delay: -0.5s;
  background: inherit;
}
.ldio-yzh88j6wa7g div:nth-child(4) {
  animation-delay: -0.75s;
  background: inherit;
}
.ldio-yzh88j6wa7g div:nth-child(5) {
  animation-delay: -1s;
  background: inherit;
}
.loadingio-spinner-ellipsis-a2dzd8rz0c {
  position: absolute;
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
}
.ldio-yzh88j6wa7g {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; 
}
.ldio-yzh88j6wa7g div {
  box-sizing: content-box;
} */
