.notification_page {
  width: 100vw;
  min-height: 100vh;
}

.notification_page__notifications {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  height: 85vh;
}
.notification_page__notifications::-webkit-scrollbar {
  display: none;
}

.notification__zero {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
}

.notification_page__title {
  text-transform: capitalize;
  font-size: 3rem;
  color: #aeb4b6;
  font-weight: 500;
}

.notification_page__icon {
  font-size: 8rem;
}
