@media screen and (orientation: portrait) {
  .container {
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: white;
    z-index: 102;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .text {
    text-align: center;
    font-size: 1.9rem;
    line-height: 100%;
  }
  .instructions_text {
    text-align: center;
    padding: 10px;
    color: grey;
    opacity: 0.8;
  }
  .instructions_text i {
    margin-left: 3px;
  }
}
.span {
  font-size: 1.1rem;
  font-weight: bold;
}
@media screen and (orientation: landscape) {
  .container {
    display: none;
  }
}
