.container {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  z-index: 10;
  top: 0px;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.top_heading {
  font-size: 2.5rem;
  font-weight: bold;
}
.wrapper {
  background-color: white;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
}
.button {
  padding: 9px;
  border-radius: 10px;
  background-color: #8dd54f;
  color: white;
  font-size: 2rem;
  font-weight: bold;
  margin-top: 3%;
  margin-bottom: 2%;
}
.heading {
  font-weight: semibold;
  font-size: 1.9rem;
  margin: 5% 0px;
  line-height: 35px;
}
.validity_para {
  font-size: 1.4rem;
  margin-bottom: 30px;
}
