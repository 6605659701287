.dropDown {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow-y: scroll;
  margin-top: 3rem;
}

.modalQuestions {
  font-weight: 500;
  font-size: 24px;
}

.cards_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
  gap: 4px;
  width: 100%;
  margin-top: 1rem;
}

.radio_cards_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-top: 1rem;
  flex-wrap: wrap;
}

.radio_btn_container {
  display: flex;
  align-items: center;
  gap: 1rem;
  border: 1px solid black;
  width: 30%;
  padding: 4px 12px;
  margin-top: 1rem;
  border-radius: 1rem;
  color: black;
}

.info_icon {
  margin-left: auto;
  cursor: pointer;
}

/* Media Queries */

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1024px) {
  .radio_btn_container {
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 480px) {
  .radio_cards_container {
    gap: 0;
  }
}
