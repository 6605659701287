.tncModalBackground {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: #1f2d3d;
  display: flex;
  align-items: center;
}

.tncModalContainer {
  z-index: 5;
  /* min-width: 10rem; */
  width: min-content;
  bottom: 0px;
  position: fixed;
  color: grey;
  font-size: 1rem;
  font-weight: 600;
  /* background-color: red; */
  transform: translate(-50px, 0);
  left: 50%;
}

.closeModalBtn {
  background-color: skyblue;
  color: white;
  padding: 4px 8px;
  border-radius: 8px;
}

.redeemCountdownModal {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-top-left-radius: 1.4rem;
  /* border-bottom-left-radius: 1.4rem;
  border-bottom-right-radius: 1.4rem; */
  padding: 1rem;
  position: relative;
  /* left: 50%; */
  /* transform: translate(-50%, 0); */
}

.smallCountdownModal {
  cursor: pointer;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-top-left-radius: 1.4rem;
  border-top-right-radius: 1.4rem;
  position: fixed;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0);
}
.closeBtn {
  background-color: white;
  position: absolute;
  cursor: pointer;
  padding: 3px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  top: -28px;
  right: 0;
}
.closeBtn:hover {
  background-color: white;
  color: red;
}
.closeBtn i {
  font-size: 1.5rem;
}

.redeem_button {
  padding: 10px;
  border-radius: 50px;
  color: white;
  background-color: #ea4c4c;
  font-weight: bold;
  font-size: 1.1rem;
}
.redeem_button:hover {
  background-color: #ea4c4c;
  scale: 1.05;
  transition: all 100ms;
}

/* FOOTER */
.footer_tab_bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 12;
  background-color: #0aa9e5;
  padding: 12px 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 2rem;
  border-radius: 8px;
}
@media screen and (orientation: portrait) {
  .footer_tab_bar {
    padding: 6px 5px;
    font-size: 0.1rem;
  }
}
.footer_payment_button {
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  background-color: transparent;
}

.footer_payment_button:hover {
  color: #fff;
}

.footer_total_selected_program {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer_total_selected_program p {
  color: white;
  font-size: 1rem;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
}

.footer_total_price_taxes {
  font-size: 12px;
}

.footer_selected_teacher {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer_selected_teacher p {
  color: white;
  font-size: 1rem;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
}
