.modal_wrapper {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.7);
  background-color: aqua;
  z-index: 1000;
}
.dialog_box_container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 10px;
}
.dialog_box_close {
  position: absolute;
  top: 0px;
  right: 0px;
}
.dialog_box_close i {
  font-size: 1.7rem;
}
.dialog_box_close:hover {
  color: red;
  cursor: pointer;
}
.dialog_box_button_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.button_confirm {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
}

.button_confirm:hover {
  background-color: #d32f2f;
}

.button_cancel {
  border: 1px solid #f44336;
  color: #f44336;
  background-color: transparent;
  padding: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
}

.button_cancel:hover {
  border: 1px solid #d32f2f;
  color: #d32f2f;
}

.dialog_box_text {
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 10px;
}

.subscription_end_details_container_pricing {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.subscription_end_details_container_settings {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .subscription_end_details_container h2 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
} */

.end_subscription_button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
}

.end_subscription_button:hover {
  background-color: #d32f2f;
}
