@font-face {
  font-family: "Nunito Black";
  src: url("fonts/Nunito-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito Regular";
  src: url("fonts/Nunito-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Sensei";
  src: url("fonts/Sensei-Medium.ttf") format("truetype");
}
#bubble {
  position: fixed;
  top: 15px;
  left: 15px;
  z-index: 1000;
  border-radius: 50%;
  font-size: 2rem;
}
#bubble_icon {
  background-color: rgba(255, 255, 255, 0.6);
  padding: 10px;
  border-radius: 50%;
  color: grey;
  cursor: pointer;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
#bubble_icon:hover {
  transition: scale 500ms;
  scale: 1.1;
}
