.emojiContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  justify-content: center;
}

.npsEmoji {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 2px;
  cursor: pointer;
}

.npsEmojiImage {
  height: 3rem;
  width: 3rem;
  margin: 8px;
  cursor: pointer;
  transition: transform 0.2s;
}

.npsEmojiImage:hover {
  transform: scale(1.2);
}

.npsTitle {
  /* color: #7cb0df; */
  color: #444444;
  font-size: 2rem;
  margin-bottom: 1rem;
  /* font-family: sensei; */
  width: 100%;
  margin: 0 auto;
}

.npsEmojiContainer {
  display: flex;
  width: 100%;
  margin: 0 auto;
  margin-top: 10px;
}

.npsEmojiRating {
  border-radius: 10rem;
  padding: 3px;
  width: 2rem;
  color: rgb(2, 31, 43);
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
}

.red {
  background-color: #ea615e;
}
.yellow {
  background-color: #f7b64c;
}
.green {
  background-color: #44bc6c;
}

@media only screen and (max-width: 1000px) {
  .npsEmojiImage {
    height: 2rem;
    width: 2rem;
  }

  .npsEmojiRating {
    padding: 2px;
    width: 1.3rem;
    font-size: 1rem;
  }

  .npsTitle {
    font-size: 1.5rem;
  }
  .emojiContainer {
    padding: 2rem;
  }
}

@media only screen and (max-width: 700px) {
  .npsEmojiImage {
    height: 1.7rem;
    width: 1.7rem;
  }

  .npsEmojiRating {
    padding: 2px;
    font-size: 10px;
    width: 1rem;
    height: 1.3rem;
  }
  .npsTitle {
    font-size: 1rem;
  }
}
