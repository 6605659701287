.excercise-page {
  position: relative;
  background-color: #daebfc;
}

.bg-img {
  min-width: 100vw;
  margin: 0;
  margin-left: -3px;
  height: 100vh;
}

.excercise-main {
  position: absolute;
  top: 1%;
  left: 2%;
  right: 3.5%;
  bottom: 1%;
  display: flex;
  gap: 2vw;
}

.activity-list {
  /* height: 100%; */
  border-radius: 30px;
  padding: 15px 0px;
  min-width: 15%;
  display: flex;
  flex-direction: column;
  gap: 3%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
  align-items: center;
  flex-basis: 150px;
  flex-grow: 0;
  flex-shrink: 0;
  overflow-x: hidden;
  /* background-color: aliceblue; */
}

.fade-effect-up {
  position: fixed;
  width: 20%;
  z-index: 1;
  pointer-events: none;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    #daebfc 70%
  );
  bottom: 0%;
  height: 4em;
}
.fade-effect-down {
  position: fixed;
  width: 20%;
  z-index: 1;
  pointer-events: none;
  background-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 0),
    #daebfc 70%
  );
  top: 0%;
  height: 4em;
}
.scroll-bottom {
  width: 100%;
  position: absolute;
  bottom: 9px;
  font-size: 4rem;
  color: white;
  cursor: pointer;
  z-index: 10;
  background-color: #26c1fc;
}
.scroll-top {
  position: absolute;
  width: 100%;
  top: 0px;
  font-size: 4rem;
  color: white;
  cursor: pointer;
  z-index: 10;
  background-color: #26c1fc;
}
.scroll-top i {
  color: white;
}
.scroll-bottom i {
  color: white;
}
.activity-list:hover .scroll-top .scroll-bottom {
  display: block;
}
.activity-list::-webkit-scrollbar {
  display: none;
}

.activity_progress_bar_container {
  width: 40%;
  position: absolute;
  top: 0;
  left: 55%;
  transform: translate(-50%, 0);
  display: flex;
  justify-content: center;
  gap: 1rem;
  z-index: 1;
  padding: 4px;
}

.activity_progress_bar {
  height: 100%;
  width: 60%;
  position: relative;
}

.community_button {
  background-color: #9b2ece;
  color: white;
  padding: 6px 10px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  height: fit-content;
}

.community_button:hover {
  background-color: #b464ed;
  color: white;
}

.bronze-medal {
  width: 2rem;
  position: absolute;
  top: 0;
  left: 33%;
  transform: translate(-100%, 0);
}

.silver-medal {
  width: 2rem;
  position: absolute;
  top: 0;
  left: 66%;
  transform: translate(-100%, 0);
}

.gold-medal {
  width: 2.1rem;
  position: absolute;
  top: 0;
  left: 100%;
  transform: translate(-100%, 0);
}

.activity-button {
  cursor: pointer;
  position: relative;
  /* color: #f4f5f8; */
  color: #f4f5f8;
  /* width: 83%; */
  width: 150px;
  transition: all 0.5s;
  /* flex-basis: 150px;
  flex-grow: 0;
  flex-shrink: 0; */
  /* transform: translate(-50%); */
  /* animation: pulse 5s infinite; */
}
.activity_card {
  background-color: #344966;
  border-radius: 100%;
  height: 150px;
  flex-basis: 150px;
  flex-grow: 0;
  flex-shrink: 0;
}
.inner-border {
  border: 7px white solid;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.activity-details {
  position: absolute;
  width: 85%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  row-gap: 1vh;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.activity-details-icon {
  font-size: 4.3vh;
}

.activity-details-text {
  font-size: 2.6vh;
  font-family: "Nunito Regular";
  font-weight: 500;
}

.activity {
  flex-grow: 1;
  padding: 1rem;
  display: flex;
  align-items: center;
  position: relative;
}

.activity-bg {
  width: 100%;
}

.activity-start-btn {
  cursor: pointer;
  position: absolute;
  width: 35%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: pulse 3s infinite;
}
.activity-name {
  text-align: center;
  font-family: sensei;
  position: absolute;
  top: 7%;
  left: 50%;
  animation: pulse 5s infinite;
  transform: translate(-50%);
  z-index: 2;
  font-size: 3.5rem;
  text-align: center;
  opacity: 0.75;
  transition: all 1s;
}

.subscribe_btn_container {
  width: fit-content;
  position: absolute;
  bottom: 50%;
  right: 2.7rem;
}

.subscribe_btn {
  background: rgb(20, 111, 181);
  background: linear-gradient(
    180deg,
    rgba(20, 111, 181, 1) 30%,
    rgba(0, 212, 255, 1) 100%
  );
  font-family: Sensei;
  font-size: 1.2rem;
  color: white;
  cursor: pointer;
  padding: 8px 1rem;
  border-radius: 8px;
}

.subscribe_btn:hover {
  color: white;
}

.trial_coupon_wrapper {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 100px;
  height: 100px;
  background-color: #fff;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
@-webkit-keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }

  50% {
    transform: translate(-50%, -50%) scale(1.1);
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }

  50% {
    transform: translate(-50%, -50%) scale(1.1);
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

@media only screen and (max-width: 1250px) {
  .activity-name {
    top: 5%;
    font-size: 2rem;
    text-align: center;
    left: 47%;
  }
  .activity-start-btn {
    width: 25%;
    left: 45%;
  }
  .activity-button {
    width: 120px;
  }
  .activity_card {
    height: 120px;
  }

  .activity-details-icon {
    font-size: 3vh;
  }

  .activity-details-text {
    font-size: 2.2vh;
  }
}

@media only screen and (max-width: 992px) {
  .subscribe_btn {
    font-size: 1rem;
  }
  .activity-name {
    top: 5%;
    font-size: 2rem;
    text-align: center;
    left: 44.5%;
  }
  .activity-start-btn {
    width: 25%;
    left: 42%;
  }
  .activity-button {
    width: 100px;
  }
  .activity_card {
    height: 100px;
  }

  .activity-details-icon {
    font-size: 5vh;
  }

  .activity-details-text {
    font-size: 3.2vh;
  }

  .community_button {
    padding: 4px 8px;
    font-size: 10px;
    height: fit-content;
  }
  .activity_progress_bar_container {
    width: 50%;
    top: 0;
    left: 55%;
    transform: translate(-50%, 0);
  }
  .bronze-medal {
    width: 1.6rem;
  }
  .silver-medal {
    width: 1.6rem;
  }
  .gold-medal {
    width: 1.6rem;
  }
}
@media only screen and (max-width: 722px) {
  .activity-name {
    top: 1%;
    font-size: 2rem;
    text-align: center;
  }
  .activity-start-btn {
    width: 25%;
    left: 35%;
  }
  /* To control left panel activity cards size  */
  .activity-button {
    width: 100px;
  }
  .activity_card {
    height: 100px;
  }
  .activity-details-icon {
    font-size: 4.3vh;
  }

  .activity-details-text {
    font-size: 3vh;
  }
  .community_button {
    padding: 4px;
    font-size: 10px;
    height: fit-content;
  }
  .activity_progress_bar_container {
    width: 60%;
    top: 0;
    left: 55%;
    transform: translate(-50%, 0);
  }
  .bronze-medal {
    width: 1.6rem;
  }
  .silver-medal {
    width: 1.6rem;
  }
  .gold-medal {
    width: 1.6rem;
  }

  .subscribe_btn {
    font-size: 12px;
  }
}
/* @media only screen and (max-width: 700px) {
  .activity-start-btn {
    width: 25%;
    left: 30%;
  }
} */
