.profile_page {
  position: relative;
  background-color: #f7f7fa;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* To hide scrollbar */
.profile_page::-webkit-scrollbar {
  display: none;
}

.profile_page__my_posts::-webkit-scrollbar {
  display: none;
}

.profile_page__my_posts {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f7fa;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 80vh;
}

.notification__zero{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
}


.profile_page__title {
  text-transform: capitalize;
  font-size: 3rem;
  color: #AEB4B6;
  font-weight: 500;
}

.profile_page__icon{
  font-size: 8rem;
}
