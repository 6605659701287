.invoice_list_container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  padding: 5px;
  width: 100%;
}
.list_item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: #d8f6ff;
  padding: 20px 5px;
  border-radius: 5px;
}
.list_item p {
  font-weight: bold;
  font-size: 1.4rem;
}
.invisible_text {
  opacity: 0;
}

.date_filter_container {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
}

.filter_date_text {
  font-size: 1.2rem;
  font-weight: 600;
}

.invoice_list_heading {
  margin: 0;
}
