.page_wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.content_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page_wrapper img {
  width: 500px;
}
.link_button {
  padding: 5px;
  border-radius: 5px;
}
.link_button:hover {
  background-color: #407bff;
  color: white;
}
@media only screen and (max-width: 992px) {
  .page_wrapper img {
    width: 300px;
  }
}
