/** @format */

.center {
  position: relative;
  height: 500px;
  top: 50%;
  left: 50%;
  width: 35rem;

  transform: translate(-50%, -50%);
}

.div2-container {
  border: 3px solid black;
  background-color: white;
  opacity: 0.7;
  margin-top: 34rem;
  width: 20vw;
  margin-left: 40%;
  padding: 0.5rem;
  font-size: 1.3rem;
  border-radius: 10px;
}

.center-bg {
  position: absolute;
  height: 460px;
  top: 45%;
  left: 50%;
  width: 35rem;
  border: 3px solid black;
  transform: translate(-50%, -50%);
  background-color: white;
  opacity: 0.8;
  border-radius: 15px;
}

.main-div-container {
  width: 100vw;
  height: 200vh;
  background-image: url("../../../../../../public/assets/parentLanding/background-cropped.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bar-chart1 {
  transform: rotate(90deg);
  width: 400px;
  height: 400px;
  position: absolute;
  left: 6rem;
  right: 2rem;
}
.bar-chart2 {
  width: 400px;
  height: 400px;
  transform: rotate(-90deg);
  position: absolute;
  left: 2rem;
}

.m-div1 {
  margin-top: 2rem;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
  color: rgb(64, 64, 64);
}
.m-div2 {
  margin-top: 3.5rem;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
  color: rgb(64, 64, 64);
}
.m-div3 {
  margin-top: 4rem;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
  color: rgb(64, 64, 64);
}
.m-div4 {
  margin-top: 4rem;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
  color: rgb(64, 64, 64);
}
.m-div5 {
  margin-top: 4rem;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
  color: rgb(64, 64, 64);
}
.m-div6 {
  margin-top: 3.5rem;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
  color: rgb(64, 64, 64);
}

.middle-div {
  width: 4rem;
  position: absolute;
  left: 14.5rem;
  height: 400px;
  padding: 3px;
}

.div2-container-content {
  padding: 0.1rem;
  display: flex;
}
.div2-content-margin {
  margin-right: 1.5rem;
}

@media all and (max-width: 1200px) {
  .div2-container {
    margin-top: 30rem;
    margin-left: 35%;
    width: 30vw;
    font-size: 1.1rem;
    /* padding: 0rem; */
  }
}

@media all and (max-width: 900px) {
  .bar-chart1 {
    width: 300px;
    height: 300px;
  }
  .center {
    top: 50%;
    left: 57%;
  }
  .div2-container {
    margin-top: 20rem;
    margin-left: 35%;
    width: 30vw;
    font-size: 0.8rem;
    padding: 0rem;
  }

  .center-bg {
    height: 370px;
    width: 27rem;
    left: 50%;
    top: 50%;
  }

  .bar-chart2 {
    width: 300px;
    height: 300px;
  }
  .center {
    position: relative;
    height: 300px;
    width: 35rem;
  }
  .boom {
    width: 100vw;
    height: 70vh;
  }
  .middle-div {
    width: 4rem;
    position: absolute;
    left: 11.4rem;
    height: 200px;
  }
  .m-div1 {
    margin-top: 1.5rem;
    font-size: 0.8rem;
  }
  .m-div2 {
    margin-top: 3rem;
    font-size: 0.8rem;
  }
  .m-div3 {
    margin-top: 3rem;
    font-size: 0.8rem;
  }
  .m-div4 {
    margin-top: 3rem;
    font-size: 0.8rem;
  }
  .m-div5 {
    margin-top: 3rem;
    font-size: 0.8rem;
  }
  .m-div6 {
    margin-top: 3rem;
    font-size: 0.8rem;
  }
  .main-div-container {
    width: 100vw;
    height: 215vh;
  }
}
