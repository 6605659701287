.tncModalBackground {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.tncModalContainer {
  z-index: inherit;
  width: 30rem;
  height: 32rem;
  background-color: white;
  color: grey;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 1rem;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  position: relative;
  padding-bottom: 8rem;
  padding-top: 4rem;
}

.horizontal_line {
  width: 100%;
  margin: 0;
}

.horizontal_line_container {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.closeModalBtn {
  background-color: skyblue;
  color: white;
  padding: 4px 8px;
  border-radius: 8px;
}

.user_avatar_container {
  position: absolute;
  width: 6rem;
  top: 0;
  transform: translate(0, -50%);
}

.user_avatar__list_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin: 2rem 0 0 0;
  padding: 1rem 0 0 0;
}

.user_avatar__list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}

.user_avatar_list__first_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.user_avatar_list__second_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.user_avatar__list__item_first_row {
  width: 4rem;
}

.user_avatar__list__item {
  width: 4rem;
  height: 4rem;
}
.selected_avatar {
  width: 4rem;
  height: 4rem;
  border: 4px solid #fc5200;
  border-radius: 2rem;
  box-sizing: border-box;
}

.profile_picture {
  width: 100%;
  height: 100%;
  border-radius: 4rem;
  cursor: pointer;
}
.close_btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}
.close_btn i {
  font-size: 2rem;
}
.user_avatar__custom_avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.confirm_avatar_button {
  background-color: #fc5200;
  color: white;
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
}
