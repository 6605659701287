.wrapper {
  top: 0px;
  z-index: 2;
  height: 100vh;
  width: 100vw;
  position: fixed;
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
}
.container {
  padding: 15px;
  padding-top: 30px;
  position: relative;
  border-radius: 10px;
  background-color: white;
  text-align: center;
}
.close_icon_container {
  top: 0px;
  right: 0px;
  position: absolute;
}
.close_icon_button {
  background-color: transparent;
  color: #d7d7d7;
  font-size: 1.2rem;
}
.close_icon_button i {
  font-size: 1.4rem;
}
.close_icon_button i:hover {
  color: red;
}
.header {
  margin-bottom: 20px;
}
.header_heading {
  font-size: 1.5rem;
  font-weight: bold;
}
.body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.coupon_input {
  font-size: 1.2rem;
  border-radius: 5px;
  padding: 10px;
  border: none;
  background-color: #f0f0f0;
}
.apply_button {
  background-color: #2fb367;
  padding: 10px 15px;
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
  border-radius: 10px;
}
.coupon_error {
  font-size: 1.2rem;
}
