.profile_picture {
  width: 2rem;
  height: 2rem;
  border-radius: 4rem;
  border: 4px solid white;
}

.users_likes_circle {
  display: flex;
  align-items: center;
}

.profile_picture:nth-child(2) {
  transform: translate(-10px, 0);
}
.profile_picture:nth-child(3) {
  transform: translate(-20px, 0);
}

.total_likes {
  font-size: 12px;
  color: grey;
}
