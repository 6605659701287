.pricing_page_wrapper {
  --tag-width: 11px;
  --title-color: #24292f;
  --title-weight: bold;
  --title-size: 1.5rem;
  --price-size: 1.2rem;
  --rupees-size: 0.8rem;
  --subtitle-color: #57606a;
}
.headings {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  padding: 0px 10px;
  line-height: 5rem;
}
.header_wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  align-items: center;
  padding-top: 2%;
}
.checkbox_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
}
.checkbox_wrapper * {
  margin-top: 1%;
  cursor: pointer;
}

.strikeout_price {
  font-size: 0.9rem;
  text-decoration: line-through;
  font-weight: 400;
}
.modal_wrapper {
  transition: all 1s;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
}
h1 {
  margin: 30px 0px;
  font-size: 2.5rem;
  font-weight: semibold;
  color: #24292f;
  text-align: center;
}
.pricing_page_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* overflow-y: scroll; */
  min-height: fit-content;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.outer_wrapper {
  min-height: 100%;
  background-color: #c2e6fe;
  /* background-color: #60c2f4; */
}
.pricing_page_wrapper::-webkit-scrollbar {
  display: none;
}
.tabs_wrapper {
  display: flex;
  width: fit-content;
  background-color: #e9e9e9;
  margin-bottom: 20px;
  border-radius: 7px;
  color: #57606a;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  position: relative;
}
.tabs {
  cursor: pointer;
  padding: 5px;
  text-align: center;
}
.tabs_para {
  margin-bottom: 0px;
  font-weight: bold;
  font-size: 1rem;
}
.tabs_para_sub {
  text-align: center;
  font-size: 0.8rem;
}
.selected_tab {
  border: #0d74ec solid 1px;
  border-radius: 7px;
  background-color: #ffffff;
}
.selected_tab .tabs_para {
  background: linear-gradient(to right, #f32170, #ff6b08, #cf23cf);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}
.info_btn {
  position: absolute;
  left: 0;
  top: -1.4rem;
  padding: 0;
  transition: 500ms;
  background-color: transparent;
}
.info_btn i {
  color: #575757;
}
.mobile_carousal_wrapper {
  white-space: nowrap;
  overflow-x: auto;
  max-width: 700px;
  padding-left: 100px;
}
.duration_span {
  font-size: 3rem;
}
.validity_para {
  margin-top: 20px;
}
.pricing_wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  justify-content: space-evenly;
  align-items: stretch;
  padding: 0px 7%;
}
@media only screen and (max-width: 700px) {
  .pricing_page_wrapper {
    justify-content: start;
  }
  .mobile_carousal_wrapper {
    display: block;
  }
  .pricing_wrapper {
    flex-wrap: wrap;
    padding: 0px 10px;
  }
  .header_wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: fit-content;
    align-items: center;
    padding-top: 2%;
  }
}
