.edit_post_modal_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.edit_post_modal {
  position: relative;
  /* width: 500px;
  height: 500px; */
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  gap: 10px;
}
.edit_post_modal_header {
  color: black;
  font-size: 1.5rem;
}
.edit_post_modal_close {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}
.edit_post_modal_close i {
  color: grey;
  font-size: 1.3rem;
}
.edit_post_modal_close i:hover {
  color: red;
}
.edit_post_modal_body {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  gap: 20px;
}
.edit_post_modal_input {
  display: flex;
  flex-direction: column;
  color: black;
}
.edit_post_modal_input input {
  border-radius: 5px;
  margin-top: 5px;
  border: 1px solid grey;
}
.edit_post_modal_file {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.edit_post_modal_file_label {
  margin-bottom: 5px;
  color: black;
}
.edit_post_modal_file input {
}
.edit_post_modal_file button {
  margin-top: 20px;
}
.edit_post_modal_file button {
  background-color: orangered;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  padding: 5px 10px;
}
.edit_post_modal_error {
  color: red;
  font-size: 0.8rem;
}
