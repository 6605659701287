.trial_coupon_wrapper {
  position: absolute;
  top: 55%;
  right: 0;
  transform: translateY(-50%);
  width: 120px;
  height: fit-content;
  padding: 5px;
  padding-left: 10px;
  background-color: #f59b19;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.trial_coupon_text {
  font-size: 1rem;
  font-weight: bold;
  color: white;
  /* color: #575c64; */
}
.trial_coupon_text span {
  font-size: 1.4rem;
  /* color: #575c64; */
}
.modal_wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}
.trophy {
  width: 80%;
}
.modal {
  width: fit-content;
  padding: 20px;
  background-color: #f2f1ed;
  border-radius: 10px;
  position: relative;
}
.modal_header {
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 20px;
}
.modal_body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.modal_header p {
  font-size: 1.4rem;
  font-weight: bold;
}
.modal_close {
  position: absolute;
  top: -20px;
  right: 0px;
  color: black;
  background-color: #f2f1ed;
  padding: 2px 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  color: #575c64;
}
.modal_close i {
  font-size: 1.5rem;
}
.modal_close:hover {
  cursor: pointer;
  color: red;
}
.redeem_button {
  padding: 10px;
  border-radius: 50px;
  color: white;
  background-color: #ea4c4c;
  font-weight: bold;
  font-size: 1.1rem;
}
.redeem_button:hover {
  background-color: #ea4c4c;
  scale: 1.05;
  transition: all 100ms;
}
