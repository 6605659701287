.community_feed {
  position: relative;
  background-color: #f7f7fa;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* To hide scrollbar */
.community_feed::-webkit-scrollbar {
  display: none;
}

.community_feed__posts::-webkit-scrollbar {
  display: none;
}

.community_feed__posts__single_post_container {
  cursor: pointer;
}

.community_feed__posts {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f7fa;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 80vh;
}
.community_feed_loading {
}
