.topic_card {
  cursor: pointer;
  overflow: hidden;
  margin: 5px 0px;
  /* width: 12rem; */
  position: relative;
}
.topic_card_popup_wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.topic_card_body {
  margin-top: 3px;
  border-radius: 5px;
  overflow: hidden;
  /* border: 6.6px solid #ea75c1;
  background-color: rgba(255, 138, 209, 1); */

  border: 6.6px solid #848884;
  background-color: #848884;
  font-size: 0.8rem;
  color: white;
  text-align: center;
  width: 100%;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  color: white;
  font-size: 12px;
  font-weight: bold;
}
.topic_card_body:hover {
  filter: contrast(1.2);
}
.topic_card_date {
  /* background-color: rgba(255, 255, 255, 0.9); */
}
.topic_card_image {
  width: 100%;
}
.topic_card_button {
  width: 100%;
  font-weight: bold;
  color: white;
  background-color: transparent;
  font-size: 0.85rem;
}

.topic_card_book_btn_container {
  display: flex;
  gap: 8px;
  align-items: center;
}

/* Media Queries */

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 480px) {
}
