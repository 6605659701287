.mainContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.comingSoonImg {
  width: 68rem;
}

/* Media Queries */

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1023px) {
  .comingSoonImg {
    width: 30rem;
  }
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 480px) {
}
