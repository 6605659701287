.invoice_container {
  width: 100%;
  display: flex;
}

.invoice_bg_container {
  width: 4rem;
  min-height: 100vh;
}

.invoice_main_container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.invoice_header__company_logo {
  width: 3rem;
  margin-right: 1rem;
}

.invoice_header__company_logo img {
  width: 100%;
}

.invoice_action_buttons {
  width: 100%;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 1rem;
}

.download_btn {
  width: fit-content;
  background-color: #eaf0f6;
  color: #5da9f0;
  border: 1px solid #5da9f0;
  padding: 8px;
  border-radius: 4px;
}

.download_btn:hover {
  background-color: #5da9f0;
  color: white;
  transition: all 0.3s ease-in;
}

.paid_btn {
  background-color: #306130;
  color: white;
  width: fit-content;
  padding: 8px;
  border-radius: 4px;
}

.invoice_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 8px 1rem;
}

.company_name {
  font-size: 12px;
  font-weight: 700;
}

.invoice_body {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.invoice_body__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 0 1rem;
}

.invoice_body__date {
  font-size: 12px;
  color: gray;
}

.invoice_body__invoice_number {
  font-size: 10px;
  font-weight: 700;
  line-height: 1rem;
}

.invoice_body__header_billto_container {
  width: 12rem;
  height: 100%;
  margin-right: 10%;
}

.billto_text {
  font-size: 10px;
  font-weight: 700;
  line-height: 1rem;
}

.invoice_body__billto_name {
  font-size: 12px;
  font-weight: 700;
  line-height: 1rem;
}

.invoice_body__billto_address {
  font-size: 10px;
  line-height: 1rem;
}

.invoice_header__company_address {
  font-size: 10px;
  color: #5d5d5d;
  line-height: 1rem;
  display: flex;
  gap: 8px;
  align-items: center;
}

.invoice_header__company_address i {
  font-size: 12px;
  color: red;
}

.invoice_body__table_container {
  width: 100%;
  padding: 8px 1rem;
}

.invoice_body__table {
  width: 100%;
}

.invoice_body__footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 8px 1rem;
}

.invoice_body__table thead tr th {
  background-color: #888888;
  color: white;
  font-size: 10px;
  padding: 4px;
}

.invoice_body__table tbody tr td {
  font-size: 10px;
  padding: 4px;
  color: #5d5d5d;
  font-weight: 600;
}

.invoice_body__table tbody tr:nth-child(2) td {
  font-weight: 800;
}

.tax_invoice {
  background-color: #0c74bb;
  color: white;
  width: fit-content;
  padding: 8px;
  font-family: Sanchez serif;
  font-size: 1.2rem;
}

.horizontal_line {
  width: 97%;
  margin: auto;
}

.invoice_body__footer_tax p {
  font-size: 10px;
  margin: 0;
  line-height: 1rem;
}

.invoice_body__footer_payment_information {
  margin-top: 1rem;
}

.invoice_body__footer_payment_information p {
  font-size: 10px;
  margin: 0;
  line-height: 1rem;
}

.invoice_body__footer_payment_information p {
  font-size: 10px;
  margin: 0;
  line-height: 1rem;
}

.footer_tax_registration_text {
  font-size: 10px;
  font-weight: 700;
}

.invoice_body__footer_total_table {
  width: 25rem;
  margin-right: 3rem;
}

.invoice_body__footer_total_table tbody tr td {
  font-size: 10px;
  color: #5d5d5d;
  font-weight: 600;
  text-align: end;
}

.invoice_body__footer_total_table tbody tr:nth-child(3) td,
.invoice_body__footer_total_table tbody tr:nth-child(5) td {
  font-weight: 800;
}
