.mainContainer{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.barGraph{
    width: 1000%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 2rem;
}

.barCompenent{
    width: 10rem;
    height: 20rem;
    /* background-color: aliceblue; */
    display: flex;
    
    justify-content: center;
    align-items: flex-end;
    

}

.bar{
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: sticky;
    bottom: 0;
    
}