.modal_wrapper {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 13;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  min-height: fit-content;
  min-width: fit-content;
  background-color: #e7f6d9;
  border-radius: 10px;
  /* height: 80vh; */
  /* width: 80vw; */
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.modal_button {
  padding: 9px;
  border-radius: 10px;
  background-color: #8dd54f;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 3%;
  margin-bottom: 2%;
  width: fit-content;
}
.modal_heading {
  color: #8dd54f;
  font-family: sensei;
}
.modal_text {
  font-size: 1.3rem;
  margin-bottom: 10px;
}
