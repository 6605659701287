.activity_wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
  /* background-image: url("https://braincells.in/course/Library/activity-files/encyclo-book/assets/backgroundImage.png"); */
  background-color: aliceblue;
  position: relative;
}
.book_wrapper {
  border-radius: 15px;
  background-color: aliceblue;
  /* background-image: url("https://braincells.in/course/Library/activity-files/encyclo-book/assets/backgroundImage.png"); */
  width: 100%;
  height: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* align-items: center; */
  position: relative;
  z-index: 2;
}
.book_blocker {
  width: 100%;
  height: 100%;
}

.language_selector {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 3;
  /* background-color: white; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.language_button {
  width: 100%;
  padding: 10px 0px;
  background-color: #ff465d;
  color: white;
  font-weight: bold;
  font-size: 1.3rem;
  border: white solid 5x;
}
.language_button_wrapper {
  transform: all;
  transition: 100ms;
  border-radius: 10px;
  overflow: hidden;
  width: 50%;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}
.language_button_wrapper:hover {
  scale: 1.05;
}
.heading {
  font-size: 1.8rem;
}
.subtitle {
  position: fixed;
  bottom: 20px;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 90%;
  text-align: center;
  padding: 2px;
  font-size: 2.5rem;
  margin: 0px auto;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-weight: bold;
  border-radius: 5px;
}
.navigation_wrapper i {
  position: fixed;
  bottom: 5px;
  color: white;
  padding: 0px 10px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  padding: 15px;
  cursor: pointer;
  font-size: 2.7rem;
}
.left_arrow {
  left: 5px;
}
.right_arrow {
  right: 5px;
}
@media screen and (max-width: 1200px) {
  .subtitle {
    font-size: 1.5rem;
  }
  .navigation_wrapper i {
    font-size: 1.5rem;
    padding: 10px;
  }
}

@media screen and (min-width: 768px) {
}
