.reports {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.reportsContainer {
  display: flex;
  height: 70vh;
  justify-content: space-around;
  width: 100vw;
  align-items: center;
}

.reportImages {
  width: 16rem;
}

.reportImage {
  height: 12rem;
  width: 100%;
}

.interest {
  align-self: flex-start;
}

@media screen and (max-width: 1024px) {
  .reportImage {
    height: 8rem;
  }
  .reportTextImage {
    height: 2rem;
    width: 100%;
  }
}
