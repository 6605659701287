.tabs_wrapper {
  display: flex;
  width: fit-content;
  background-color: #e9e9e9;
  margin-bottom: 20px;
  border-radius: 7px;
  color: #57606a;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  position: relative;
}
.tabs {
  cursor: pointer;
  padding: 5px;
  text-align: center;
}
.tabs_para {
  margin-bottom: 0px;
  font-weight: bold;
  font-size: 1rem;
}
.tabs_para_sub {
  text-align: center;
  font-size: 0.8rem;
}
.selected_tab {
  border: #0d74ec solid 1px;
  border-radius: 7px;
  background-color: #ffffff;
}
.selected_tab .tabs_para {
  background: linear-gradient(to right, #f32170, #ff6b08, #cf23cf);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}
.info_btn {
  position: absolute;
  left: 0;
  top: -1.4rem;
  padding: 0;
  transition: 500ms;
  background-color: transparent;
}
.info_btn i {
  color: #575757;
}
