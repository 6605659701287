.mainContainer{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.creationContainer{
    display: flex;
    flex-direction:column ;
    align-items: center;
    justify-content: center;
    height:60%;
    width: 70%;
    max-height: 60rem;
    gap: 2rem;
    padding: 10px;
    border-radius: 20px;
    box-shadow: 0px -1px 10px 1px rgba(0, 0, 0, 0.4);
}
.creationContainer h1{
    font-size: 200%;
}


.creationContainer h2{
    font-size: 400%;
}
.checklist{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center ;
    flex-wrap: wrap;
    width: 100%;
    height: 60%;
    gap: 10px;
    overflow: scroll;
}
.checkBox{
    display: flex;
    gap:10px;
    width: 30%;
    
}
@keyframes enlarge{
    100%{
        transform: scale(1.2);
    }
}

@media only screen and (max-height: 426px) and (orientation:landscape) {
    .avatarComponent{
            border-radius: 30px;
        
    }
    .creationContainer{
        height: 100%;
        max-height: 100%;
        width: 100%;
        box-shadow: 0px -1px 10px 1px rgba(0, 0, 0, 0);
    }
    
  }

