.mainContainer {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.creationContainer {
  width: 90%;
  height: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  overflow-y: auto;
}
.professionsList {
  height: 70%;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.interestComponent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 12rem;
  height: 12rem;
  border-radius: 50%;
}

@media only screen and (max-width: 426px) {
  .mainContainer {
    grid-template-columns: 100%;
    grid-template-rows: 50% 50%;
  }
  .creationContainer {
    height: 90%;
  }
  .professionsList {
    height: 90%;
  }
  .interestComponent {
    width: 8rem;
    height: 8rem;
  }
}
@media only screen and (max-height: 426px) and (orientation: landscape) {
  .creationContainer {
    height: 90%;
    width: 90%;
  }
  .professionsList {
    height: 90%;
    width: 100%;
  }
}
