.checkbox_label {
  color: #344966;
}
.checkbox_container {
  margin-left: 0%;
}
.checkbox_container {
  margin-left: 10%;
  animation-duration: 1.5s;
  animation-iteration-count: 2;
}
.checkbox_container input {
  margin-right: 5px;
  color: #344966;
}
