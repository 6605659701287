.tncModalBackground {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.tncModalContainer {
  z-index: inherit;
  width: 30rem;
  height: 27rem;
  background-color: white;
  color: grey;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  border-radius: 1rem;
  position: relative;
}

.closeBtn {
  position: absolute;
  top: -3rem;
  right: 0;
  font-size: 2rem;
  cursor: pointer;
  color: white;
}
.closeBtn i {
  font-size: 2rem;
}

.subscribe_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.subscribe_container h2 {
  text-align: center;
}

.horizontal_line {
  width: 100%;
  margin: 0;
}

.or_text {
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
  font-family: monospace;
}

.refer_friend_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.refer_friend_container h2 {
  text-align: center;
}

.form_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.form_input {
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid #36454f;
  font-family: monospace;
  color: #36454f;
}

.subscribe_text {
  color: #36454f;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  font-family: monospace;
}

.refer_text {
  color: #36454f;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  font-family: monospace;
}

.action_btn {
  background-color: #f37335;
  padding: 8px 1rem;
  border-radius: 1rem;
  color: white;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 4px;
}

.action_btn:hover {
  filter: brightness(1.2);
  transition: all 0.2s ease-in;
}

.error_text {
  border: 2px solid crimson;
  color: crimson;
  padding: 4px 1rem;
  border-radius: 8px;
}
