.booking_page_wrapper {
  width: 100vw;
  min-height: fit-content;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  background-color: #f3f4f5;
  padding-bottom: 80px;
  position: relative;
  background-size: cover;
}
.closeModalBtn {
  color: black;
  padding: 4px 8px;
  border-radius: 8px;
  height: fit-content;
  width: fit-content;
  position: absolute;
  top: 1rem;
  left: 1rem;
  cursor: pointer;
  opacity: 50%;
}

.footer_price_container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.footer_price_container p:nth-child(1) {
  text-decoration: line-through;
}

.footer_price_container p:nth-child(2) {
  font-size: 1.2rem;
}

.footer_price_container p:nth-child(3) {
  font-size: 1.4rem;
}

.line_through {
  text-decoration: line-through;
}

.closeModalBtn i {
  font-size: 2rem;
}
.booking_page {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  width: 45%;
  min-width: fit-content;
  height: min-content;
  padding-bottom: 30px;
  border: #0aa9e5 1px solid;
}
.page_heading {
  font-size: 2rem;
  font-weight: semibold;
}
/* AVATAR */
.avatar_container {
  margin-top: 10px;
  width: 93px;
  height: 93px;
  border-radius: 50%;
  border: grey solid 2px;
}
.avatar {
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  width: 90px;
  height: 90px;
}
/* TEACHER INFO */
.teacher_info_container {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.teacher_info_name {
  font-size: 1.5rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.65);
}
.teacher_info_text {
  font-size: 1rem;
  font-weight: 400;
  color: black;
  margin-top: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.teacher_info_description {
  width: 50%;
  margin-top: 10px;
  color: #6c757d;
  margin-bottom: 10px;
}
/* DATE TIME SELECTION */
.selection_wrapper {
}
.selection_container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  background-color: #f9f9fa;
  width: fit-content;
  padding: 10px 20px;
  margin: auto;
  border-radius: 10px;
  margin-top: 10px;
  position: relative;
}
.selection_confirmation {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(256, 256, 256, 0.9);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.15s ease-in-out;
  flex-direction: column;
  gap: 10px;
}
.confirmation_button {
  background-color: #2d69fb;
  color: white;
  border-radius: 5px;
  padding: 7px 5px;
  font-weight: bold;
  width: 100%;
}
.confirmation_cancel {
  /* background-color: white; */
  color: #f7665e;
  border-radius: 5px;
  padding: 7px 5px;
  font-weight: bold;
  width: 100%;
  border: 1px solid #f7665e;
  background-color: white;
}
.confirmed_complete {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(256, 256, 256, 0.9);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.15s ease-in-out;
  flex-direction: column;
  gap: 10px;
}
.confirmed_para {
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
}
.selection_wrapper_label {
  font-size: 1.2rem;
  font-weight: 600;
  color: black;
  width: 100%;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
}
.date_selection {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-direction: column;
}
.date_selected {
  background-color: #2d69fb;
  color: white;
  border-radius: 5px;
  padding: 7px 5px;
  font-size: 1rem;
  padding: 12px;
  font-weight: 600;
  width: 100%;
}
.date_selected p {
  color: white;
}
.date_selection_date {
  background-color: #eef5ff;
  border-radius: 5px;
  padding: 7px 5px;
  font-size: 1rem;
  color: #2d69fb;
  border: 1px solid #2d69fb;
  padding: 12px;
  font-weight: 600;
  width: 100%;
}
.date_selection_date p {
  color: #2d69fb;
}
.date_selection_date:hover {
  cursor: pointer;
  transition: 0.15s ease-in-out;
  background-color: #2d69fb;
  color: white;
}
.date_selection_date:hover p {
  transition: 0.15s ease-in-out;
  color: white;
}
.time_selection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  flex-wrap: wrap;
  /* flex-direction: column; */
}
.time_selected {
  background-color: #2d69fb;
  color: white;
  border-radius: 15px;
  padding: 7px 5px;
  font-size: 1rem;
  padding: 10px;
  font-weight: 600;
  border: #2d69fb 1px solid;
}
.time_selection_time {
  font-size: 1rem;
  color: #2d69fb;
  border-radius: 15px;
  padding: 7px 5px;
  font-size: 1rem;
  padding: 10px;
  font-weight: 600;
  border: #2d69fb 1px solid;
}
.time_selection_time:hover {
  cursor: pointer;
  transition: 0.15s ease-in-out;
  background-color: #2d69fb;
  color: white;
}
/* BOOKING CARDS */
.bookings_label {
  font-size: 1.2rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.65);
}
.bookings_container {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.booking_cards {
  /* margin-top: 20px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  background-color: #f9f9fa;
  border-radius: 10px;
  color: #53555a;
  gap: 15px;
}

/* PAYMENT BUTTON */
.payment_info_container {
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #cdf0ea;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.payment_amount {
  font-size: 1rem;
  font-weight: 600;
  color: #17be88;
  margin-top: 10px;
}
.payment_button {
  margin-top: 10px;
  margin-bottom: 15px;
  background-color: #17be88;
  color: white;
  border-radius: 10px;
  padding: 8px 16px;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
}
.payment_button:hover {
  scale: 1.1;
  transition: 0.15s ease-in-out;
}

.footer_tab_bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 12;
  background-color: #0aa9e5;
  padding: 12px 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 2rem;
  border-radius: 8px;
}

.footer_payment_button {
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.2rem;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
}

.footer_payment_button p {
  color: #fff;
  font-weight: 600;
  font-size: 1.2rem;
}

.footer_payment_button:hover {
  color: #fff;
}

.footer_total_selected_program {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer_total_selected_program p {
  color: white;
  font-size: 1rem;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
}

.footer_total_price_taxes {
  font-size: 12px;
}

.footer_selected_teacher {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer_selected_teacher p {
  color: white;
  font-size: 1rem;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
}

.footer_selected_teacher_name {
  align-self: center;
}
