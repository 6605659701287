/* SINGLE ACTIVITY STARTER */
.activity_container {
  position: fixed;
  z-index: 2;
  top: 0px;
  height: 100vh;
  width: 100vw;
}
.activity_wrapper {
  height: 100%;
  width: 100%;
  position: relative;
}
.activity_cross {
  z-index: 10;
  position: fixed;
  top: 2px;
  right: 2px;
  transition: all 200ms;
}
.activity_cross i {
  color: red;
  font-size: 1.8rem;
}
.activity_cross:hover {
  cursor: pointer;
  scale: 1.1;
}
