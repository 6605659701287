.community_navbar {
  top: 0;
  width: 100%;
  /* background-color: #9cdeff; */
  background-color: #fc5200;
  color: white;
  height: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification_container {
  margin-right: 1rem;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
.notification_container a i {
  font-size: 1.5rem;
}
.community_navbar__heading_container {
  margin-left: 1rem;
}

.community_navbar__heading {
  font-size: 1rem;
  font-weight: 600;
  color: white;
}

.notification_container__profile_picture {
  width: 2rem;
}
.community_navbar_notification_wrapper {
  position: relative;
}
.notification_number {
  color: black;
}
.notification_number_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  font-size: 0.75rem;
  position: absolute;
  bottom: -3px;
  right: -10px;
  background-color: aliceblue;
}
