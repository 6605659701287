.path {
  position: relative;
}

.path-img {
  min-width: 100vw;
  max-height: 100vh;
  object-fit: cover;
  margin: 0;
  margin-left: -3px;
}
.animation_container {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: white;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(255, 255, 255, 0.5); */
  background-color: rgba(0, 0, 0, 0.3);
}
.content_container {
  background-color: #ffeab7;
  border-radius: 15px;
  height: 90%;
  width: 70%;
  min-width: min-content;
  /* width: min-content; */
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  background-image: url("/public/assets/program/c-bg.jfif");
  background-size: cover;
  position: relative;
  overflow: hidden;
}
.content_wrapper {
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.background_blur {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.7);
}
.animation_image {
  height: 70%;
  display: flex;
  justify-items: center;
  align-items: center;
}
.animation_image_img {
  height: 100%;
  margin: auto;
  /* background-color: rgba(255, 255, 255, 0.1); */
}
.reward_button {
  background-color: #ffb603;
  padding: 8px 13px;
  color: white;
  font-size: 1.5rem;
  border-radius: 70px;
  margin-top: 20px;
  font-weight: bold;
  border: 4px solid #d38550;
  font-family: sensei;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  transition: all 500ms;
}
.reward_button:hover {
  scale: 1.1;
}
@media screen and (max-width: 1024px) {
  .content_container {
    height: 90%;
    width: 70%;
  }
}
