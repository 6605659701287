.booking_card {
  display: flex;
  gap: 20px;
  /* background-color: #81a5fc; */
  background-color: #98d960;
  color: white;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  justify-content: space-between;
}
