@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

.captchaBackground {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  cursor: default;
  display: flex;
}

.captchaContainer {
  width: 13.6rem;
  height: 20rem;
  background-image: url(/public/assets/parentLogin/background.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* position: relative; */
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-left: auto;
  margin-top: 1rem;
  margin-right: 1.5rem; */
  top: 1rem;
  right: 1.5rem;
}

.parentLoginHeading {
  color: grey;
  font-size: 1.2rem;
  position: absolute;
  top: 1rem;
}

.equationContainer {
  position: absolute;
  color: grey;
  font-size: 1rem;
  top: 4rem;
  display: flex;
  align-items: center;
  gap: 8px;
}

.numberKeypadContainer {
  position: absolute;
  top: 6rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.numberKeypadRow {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 8px;
  width: 100%;
}

.numberKey {
  width: 25%;
  position: relative;
  cursor: pointer;
}

.singleNumberKey {
  position: absolute;
  top: 12px;
  left: 1.3rem;
  font-size: 1rem;
  font-weight: bold;
  color: white;
}

.outputDiv {
  width: 4rem;
  height: 1.3rem;
  border: 2px solid gray;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Nunito", sans-serif;
}

.backButtonContainer {
  position: absolute;
  top: 16.2rem;
  width: 3rem;
  cursor: pointer;
}

.refreshIcon {
  width: 1.4rem;
  cursor: pointer;
}

.animate__backInRight {
  --animate-duration: 0.5s;
}

/* Media Queries */

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1024px) {
  .captchaContainer {
    width: 10rem;
    height: 15rem;
  }
  .numberKeypadContainer {
    gap: 0px;
  }
  .equationContainer {
    gap: 2px;
  }
  .singleNumberKey {
    top: 6px;
    left: 14px;
  }
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 480px) {
}
