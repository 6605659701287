.tncModalBackground {
  width: 100vw;
  height: 100%;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  background-color: white;
}

.tncModalContainer {
  z-index: inherit;
  width: 100%;
  height: fit-content;
  top: 0;
  left: 5rem;
  background-color: #d5c6ff;
  background-color: white;
  color: grey;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  margin-bottom: 5rem;
}

.heading {
  font-size: 1.4rem;
  font-weight: bold;
}

.teacher_cards_container {
  margin-top: 2rem;
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 1rem;
}

.closeModalBtn {
  color: black;
  padding: 4px 8px;
  border-radius: 8px;
  height: fit-content;
  width: fit-content;
  position: absolute;
  top: 1rem;
  left: 1rem;
  cursor: pointer;
  opacity: 50%;
}

.closeModalBtn i {
  font-size: 2rem;
}

/* Media Queries */

@media screen and (max-width: 1280px) {
  .teacher_cards_container {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 768px) {
  .teacher_cards_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 480px) {
}
