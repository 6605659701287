.container {
  width: fit-content;
  margin-left: 50%;
  transform: translate(-50%);
  padding-top: 2rem;
}

.start_activity_btn {
  padding: 12px 16px;
  background-color: #0d8ecc;
  color: white;
  border-radius: 8px;
  font-family: sensei;
  font-size: 1.2rem;
  width: max-content;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
